@import 'app.less';
@import 'inputStyle.less';
@import 'buttonStyle.less';
@import 'tabsStyle.less';
@import 'drawerStyle.less';
@import 'breadCrumbStyle.less';
@import 'paginationStyle.less';
@import 'collapseStye.less';
@import 'modalStyle.less';
@import 'tableStyle.less';
@import 'popoverStyle.less';

.ant-spin-dot-item {
  background-color: var(--color-primary) !important;
}

.select-disable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.transition-all {
  transition: all 0.2s ease-in;
}

  
h3.ant-typography, div.ant-typography-h3, div.ant-typography-h3 > textarea, .ant-typography h3{
  font-weight:  700;
  font-size: 24px;
  font-style: normal;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-paragraph>li {
  background: linear-gradient(90deg, rgba(214, 210, 210, 0.726) 25%, rgba(138, 133, 133, 0.15) 37%, rgba(209, 206, 206, 0.493) 63%);
  background-size: 400% 100% !important;
  animation-duration: 1.4s !important;
  animation-timing-function: ease !important;
  animation-iteration-count: infinite !important;
  height: 8px !important;
}
  
  