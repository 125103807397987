.f1b5qwcc{width:100%;}
.f1jt72c0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:24px;}
.f1narnsc{font-size:18px;color:var(--color-white);margin:0;}
.e157iptv{text-align:center;padding:48px 24px;background-color:var(--color-90-black);border-radius:8px;color:var(--color-60-gray);}
.crmq2u2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:16px;padding:0 12px 8px;border-bottom:1px solid var(--color-80-black);margin-bottom:16px;}
.h16wslys{color:var(--color-60-gray);font-size:12px;text-transform:uppercase;font-weight:500;}.h16wslys:nth-child(1){width:200px;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;}.h16wslys:nth-child(2){-webkit-flex:1;-ms-flex:1;flex:1;}.h16wslys:nth-child(3){width:120px;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;}
.hcom6tp{width:32px;}
.s1x39mg8{overflow-y:auto;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:12px;padding-right:4px;margin-bottom:8px;}.s1x39mg8::-webkit-scrollbar{width:6px;}.s1x39mg8::-webkit-scrollbar-track{background:var(--color-90-black);border-radius:3px;}.s1x39mg8::-webkit-scrollbar-thumb{background:var(--color-70-black);border-radius:3px;}
.cf2u3vk{background-color:var(--color-90-black);border-radius:8px;padding:16px;min-height:200px;}
.i1n6vprn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;gap:16px;border-radius:8px;padding:12px;position:relative;-webkit-transition:background-color 0.2s ease;transition:background-color 0.2s ease;margin-bottom:10px;}.i1n6vprn .ant-form-item{margin-bottom:0;position:relative;}.i1n6vprn .ant-form-item:nth-child(1){width:200px;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;}.i1n6vprn .ant-form-item:nth-child(2){-webkit-flex:1;-ms-flex:1;flex:1;min-width:0;}.i1n6vprn .ant-form-item:nth-child(3){width:120px;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;}.i1n6vprn .ant-form-item-explain{position:absolute;top:calc(100% + 4px);left:0;font-size:12px;white-space:nowrap;z-index:1;}.i1n6vprn .ant-select,.i1n6vprn .ant-input{background-color:var(--color-80-black);color:var(--color-white);-webkit-transition:border-color 0.2s ease;transition:border-color 0.2s ease;}.i1n6vprn .ant-select:hover,.i1n6vprn .ant-input:hover{border-color:var(--color-link);}.i1n6vprn .ant-select-focused .ant-select-selector,.i1n6vprn .ant-input:focus{border-color:var(--color-link) !important;box-shadow:0 0 0 2px rgba(220,90,75,0.2) !important;}
.c44zn65{width:100% !important;}
.c128051a{width:100%;}
.c1qbvq3b{min-width:130px !important;}
.d619yww{color:var(--color-tag-red-txt);cursor:pointer;font-size:16px;padding-left:8px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:32px;-webkit-transition:color 0.2s ease;transition:color 0.2s ease;margin-top:10px;}.d619yww:hover{color:var(--color-tag-red-border);}
.d1v0xsw8{opacity:1;}
.a15katit{background-color:var(--color-link);border:none;-webkit-transition:all 0.2s ease;transition:all 0.2s ease;}.a15katit:hover{background-color:var(--color-primary-tint-90);-webkit-transform:translateY(-1px);-ms-transform:translateY(-1px);transform:translateY(-1px);}.a15katit:active{-webkit-transform:translateY(0);-ms-transform:translateY(0);transform:translateY(0);}
.e185q927{margin-top:16px;color:var(--color-tag-red-txt);padding:8px 12px;background-color:var(--color-80-black);border-radius:4px;border:1px solid var(--color-tag-red-txt);}
.t3k6sgq{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:8px;}
.i1hx9uvt{color:var(--color-60-gray);font-size:14px;cursor:help;-webkit-transition:color 0.2s ease;transition:color 0.2s ease;}.i1hx9uvt:hover{color:var(--color-link);}
.to71sf6{padding:5px;}.to71sf6 .ant-typography,.to71sf6 .ant-list-item{color:var(--color-80-black) !important;-webkit-user-select:text !important;-moz-user-select:text !important;-ms-user-select:text !important;user-select:text !important;}
.ipz99yi{padding:20px;background-color:var(--color-90-black);border-radius:8px;box-shadow:0 2px 4px rgba(0,0,0,0.1);margin-bottom:10px;}
.i1mwxhu6{margin-bottom:10px;}
.i1wpldc{margin-bottom:5px;}
.iha4gfb{list-style-type:none;padding:0;margin-bottom:5px;}
.i26nlq5{margin-bottom:10px;padding:0px 0px !important;border-radius:4px;}
.i1iiio94{font-size:14px;}
.c57v6pn{color:var(--color-60-gray);font-size:16px;cursor:pointer;-webkit-transition:color 0.3s ease;transition:color 0.3s ease;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;}.c57v6pn:hover{color:var(--color-link);}
.s1s8d5qh{color:var(--color-link);font-size:14px;text-overflow:ellipsis;overflow:hidden;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;