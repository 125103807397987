.f1sbmlia{width:100%;}
.g4l9lkn{margin-bottom:32px;}
.t17jnq76{color:var(--color-white);font-size:24px;font-weight:500;margin-bottom:2px;}
.sq2qzev{display:block;font-size:14px;line-height:1.6;color:var(--color-gale-gray) !important;}
.fra44r8{background:var(--color-90-black);border-radius:8px;padding:24px;margin-bottom:32px;}.fra44r8 .ant-form-item{margin-bottom:28px;}.fra44r8 .ant-form-item:last-child{margin-bottom:0;}.fra44r8 .ant-form-item-label > label{color:var(--color-white);font-size:14px;}
.izrdaia{background-color:var(--color-80-black);border:1px solid var(--color-70-black);color:var(--color-white);font-size:14px;}.izrdaia:hover,.izrdaia:focus{border-color:var(--color-link);background-color:var(--color-80-black);}.izrdaia::-webkit-input-placeholder{color:var(--color-40-black);}.izrdaia::-moz-placeholder{color:var(--color-40-black);}.izrdaia:-ms-input-placeholder{color:var(--color-40-black);}.izrdaia::placeholder{color:var(--color-40-black);}
.t1e4jui{background-color:var(--color-80-black);border:1px solid var(--color-70-black);color:var(--color-white);min-height:120px;font-size:14px;}.t1e4jui:hover,.t1e4jui:focus{border-color:var(--color-link);background-color:var(--color-80-black);}.t1e4jui::-webkit-input-placeholder{color:var(--color-40-black);}.t1e4jui::-moz-placeholder{color:var(--color-40-black);}.t1e4jui:-ms-input-placeholder{color:var(--color-40-black);}.t1e4jui::placeholder{color:var(--color-40-black);}
.t161l3qd{width:100%;}
.t1lns3f8{display:block;margin-bottom:16px;font-size:14px;color:var(--color-60-gray);}
.t167lvte{display:grid;grid-template-columns:repeat(3,1fr);gap:16px;}@media (max-width:768px){.t167lvte{grid-template-columns:1fr;}}
.th6a2q7{background:var(--color-90-black);border:1px solid var(--color-80-black);border-radius:8px;padding:16px;cursor:pointer;-webkit-transition:all 0.2s ease;transition:all 0.2s ease;}.th6a2q7:hover{border-color:var(--color-link);-webkit-transform:translateY(-1px);-ms-transform:translateY(-1px);transform:translateY(-1px);}
.tytw9jn{display:block;margin-bottom:4px;color:var(--color-white);}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;