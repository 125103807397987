.l152ljtx{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:16px 20px;background-color:var(--color-90-black);border-radius:var(--card-border-radius);-webkit-transition:all 0.3s ease;transition:all 0.3s ease;box-shadow:0px 1px 4px rgba(0,0,0,0.1);border-left:4px solid transparent;}.l152ljtx:hover{box-shadow:0px 6px 20px rgba(0,0,0,0.3);background-color:var(--color-hover-black);}
.n410bqi{-webkit-flex:2;-ms-flex:2;flex:2;min-width:150px;padding-right:16px;}
.t1eqdrvs{color:var(--color-white);font-size:16px;font-weight:500;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.sups5ro{width:120px;}
.s1akh6hw{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:4px;font-size:14px;font-weight:500;}
.pncr4lw{-webkit-flex:1;-ms-flex:1;flex:1;min-width:180px;}.pncr4lw .ant-progress{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.pncr4lw .ant-progress-outer{-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;}
.d1ojame8{-webkit-flex:1;-ms-flex:1;flex:1;}
.dnrrxbe{-webkit-flex:1;-ms-flex:1;flex:1;min-width:120px;padding-right:16px;}
.a1kdeji7{-webkit-flex:0 0 auto;-ms-flex:0 0 auto;flex:0 0 auto;min-width:80px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:20rem;}
.m19lfuko{color:var(--color-30-gray);font-size:12px;}
.v1993ph5{color:var(--color-link);font-size:14px;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:4px;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;