input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-white);
  background-color: transparent !important;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: none;
}

.ant-form-item-explain-error {
  color: var(--color-primary-tint-90) !important;
}

.ant-picker-outlined.ant-picker-disabled input[disabled] {
  opacity: 0.6 !important;
}

.ant-input-number-outlined.ant-input-number-disabled input[disabled] {
  color: var(--color-30-gray) !important;
}

.ant-input,
.ant-input-password {
  padding: 13px 16px !important;
  font-size: 1.143rem;
  border: 1px solid var(--color-60-black);
  border-radius: 0px !important;
  background: transparent;
  color: white;

  &:active,
  &:hover,
  &:focus {
    box-shadow: none;
    border: 1px solid var(--color-60-black) !important;
    background: var(--color-hover-black) !important;
    color: white;
    outline: none;
  }

  .ant-input,
  .ant-input-password {

    &:hover,
    &:focus {
      border: 0 !important;
      outline: none
    }
  }

  &:disabled,
  &:disabled:active,
  &:disabled:hover,
  &:disabled:focus {
    background: transparent !important;
    border: 1px solid var(--color-60-black) !important;
    color: white;
    opacity: 0.6;
  }
}

.ant-input-password {
  padding: 0 !important;
  padding-right: 16px !important;
}

.ant-input-number-focused {
  border: 1px solid var(--color-60-black) !important;
  background: transparent !important;
}

.ant-input-number {
  background: transparent;
  color: white !important;
  border: 1px solid var(--color-60-black);
  border-radius: 0px !important;
  padding: 13px 16px !important;

  &:hover,
  &:focus {
    box-shadow: none;
    border: 1px solid var(--color-60-black) !important;
    background: var(--color-hover-black) !important;
    color: white;
  }

  .ant-input {

    &:hover,
    &:focus {
      border: none !important;
      outline: none !important;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
    box-shadow: none;

    &:hover,
    &:focus {
      box-shadow: none;

      .ant-input-number-input {
        box-shadow: none;
      }
    }
  }

  .ant-input-number-input {
    padding: 0;
    color: white !important;
    border: none;
    box-shadow: none;
    height: auto;

    &:hover,
    &:focus {
      box-shadow: none;
      background: transparent !important;
      border: none !important;
      outline: none;
    }
  }
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: var(--color-gale-gray) !important;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  background-color: transparent !important;
}

.ant-form-item-label>label {
  color: var(--color-gale-gray) !important;
}

.ant-form-item .ant-form-item-extra {
  color: #696969;
}

.ant-form-item-has-error .ant-form-item-control .ant-form-item-control-input .ant-input,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  outline: none;
  box-shadow: none;
  background: var(--color-hover-black) !important;
  color: white !important;
  font-size: 1.143rem;
  border-color: var(--color-primary-tint-90);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none !important;
  border-radius: 0px !important;
  background: transparent !important;
  border: 1px solid var(--color-60-black) !important;
  height: auto !important;
  padding: 10px 16px !important;

  .ant-select-selection-item {
    font-size: 1rem; //1.143rem;
  }

  &:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: transparent;
  }

  &:hover {
    box-shadow: none;
    border-color: transparent;
  }
}

&.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: var(--color-input-placeholder) !important;
}

.ant-select.small:hover .ant-select-selector,
.ant-select.small .ant-select-selector {
  padding: 0 16px !important
}

.ant-select-arrow {
  color: white;
}

.ant-select-dropdown {
  background: var(--color-full-black);
  color: white;
  border: 1px solid var(--color-60-black);
  padding: 0;
  font-size: 1.143rem;

  .ant-select-item-option {
    color: white;
    padding: 12px 16px;
    // font-size: 1.143rem;
  }

  .ant-select-item-option-active {
    color: white !important;
    background-color: var(--color-charcoal-black) !important;
  }

  .ant-select-item-option-selected {
    color: white !important;
    background-color: var(--color-charcoal-black) !important;
  }
}

.ant-select-dropdown-empty {
  background: var(--color-full-black);
  color: white;
}

.ant-empty-small {
  color: white;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: white;
  display: flex;
  align-items: center;
  font-size: 1.143rem;
}

// checkbox
.ant-checkbox-wrapper {
  color: var(--color-white);
  font-size: 1rem;
  font-weight: 700;
  border: none;

  .ant-checkbox {
    color: var(--color-white);
  }

  .ant-checkbox-inner {
    top: 0 !important;
    border-radius: 0 !important;
    border: 1px solid var(--color-40-black) !important;
    background: transparent !important;
  }
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  span {
    color: gray !important;
  }

  .ant-checkbox-checked+span {
    color: var(--color-white) !important;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  top: 0 !important;
  border-radius: 0 !important;
  border: 1px solid var(--color-primary) !important;
  background: transparent !important;
}

.ant-checkbox-wrapper .ant-checkbox-checked:hover .ant-checkbox-inner,
.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background: white !important;
}

.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: black;
}

// search button
.ant-input-search {
  border: 1px solid var(--color-60-black);
}

.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child {
  // border:none;
  border: 1px solid var(--color-60-black);
  color: var(--color-white);
  background: transparent;
  padding: 0 8px;

  &:hover,
  &:focus {
    background: var(--color-full-black) !important;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    background: transparent !important;
  }
}

.ant-input-group {

  &:hover,
  &:focus {
    border: none;
  }
}

.ant-input-group-addon {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  border: none;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0;
  background-color: transparent;
  border: none;
  padding: 16px !important;
  color: var(--color-white) !important;
}

.ant-picker-clear {
  color: white;
  background: var(--color-full-black) !important;
}

// date picker

.ant-picker {
  background-color: transparent !important;
  border-radius: 0px;
  width: 100%;
  border-color: var(--color-60-black) !important;
  padding: 2px 11px;

  &:hover,
  &.ant-picker-focused {
    border-color: var(--color-60-black) !important;
    outline: none;
    box-shadow: none;
  }

  &.ant-picker-range {
    background-color: transparent !important;
    border-radius: 0px;
    width: 100%;
    height: fit-content;
    color: white !important;
    border-color: var(--color-60-black) !important;
    padding: 3px 11px;
    box-shadow: none;

    :hover {
      border-color: var(--color-60-black) !important;
      box-shadow: none;
    }

    .ant-picker-input {
      padding: 13px 0px !important;

      input {
        font-size: 1.143rem;
        color: white;
        line-height: 100%;
      }
    }

    .ant-picker-active-bar {
      background: var(--color-primary) !important;
    }
  }

  & .ant-picker-separator {
    color: white !important;
    opacity: 60%;
  }

  & .ant-picker-suffix {
    color: white;
    opacity: 60%;
  }

  .ant-picker-input {
    padding: 13px 0px !important;

    input {
      font-size: 1.143rem;
      color: white !important;
      line-height: 100%;
    }
  }
}

.ant-picker-dropdown {
  .ant-picker-range-arrow::before {
    background: var(--color-70-black) !important;
  }

  .ant-picker-time-panel-column >li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: var(--color-80-black) !important;
  }

  .ant-picker-presets {
    li:hover {
      background: var(--color-primary) !important;
    }
  }
  .ant-picker-presets,
  .ant-picker-panel {
    background: var(--color-70-black) !important;
    border: none;

    .ant-picker-header button {
      color: var(--color-white) !important;

      &:hover {
        color: var(--color-primary) !important;
      }
    }

    .ant-picker-content th {
      color: var(--color-white) !important;
    }

    .ant-picker-content .ant-picker-cell {
      color: var(--color-white) !important;
      opacity: 0.2;
    }

    .ant-picker-content .ant-picker-cell-in-view {
      opacity: 1;

      &.ant-picker-cell-today {
        border-radius: 0;
        border-color: var(--color-primary) !important;
      }
    }

    .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-color: var(--color-60-black) !important;
    }
  }

  .ant-picker-footer {
    color: var(--color-white) !important;
    background-color: var(--color-70-black) !important;
    border: none;

    .ant-picker-preset .ant-tag {
      color: var(--color-tag-orange-txt) !important;
      font-weight: 600;
    }

    .ant-picker-today-btn {
      color: var(--color-white) !important;

      &.ant-picker-today-btn-disabled {
        color: var(--color-60-black) !important;
      }
    }
  }
}

//select
.ant-select {
  &.ant-select-selector {
    background-color: white !important;
    border-radius: 8px !important;
    border-color: var(--color-60-black) !important;

    .ant-select-selection-item {
      color: var(--color-white);
      background-color: transparent;

      .ant-select-selection-item-remove {
        color: var(--color-white);

        :hover {
          color: var(--color-white);
        }
      }
    }
  }

  & .ant-select-arrow {
    color: var(--color-white) !important;
  }

  & .ant-select-clear {
    color: var(--color-50-black) !important;
    background-color: transparent;

    :hover {
      color: var(--color-50-black) !important;
    }
  }

  .ant-select-selection-search-input {
    color: var(--color-white) !important;
  }

  .ant-select-selection-overflow {
    padding: 1px 0;
  }

  .ant-select-selection-overflow-item {
    max-width: 70%;
  }

  &.ant-select-multiple .ant-select-selection-item {
    font-size: 12px !important;
    padding-inline-start: 2px;
    padding-inline-end: 2px;
    background: var(--color-white);
    color: var(--color-full-black);
  }

  &.ant-select-multiple .ant-select-selection-placeholder {
    font-size: 1.143rem;
    left: 16px;
    right: 16px;
  }

  &.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    border-color: transparent;
    opacity: 0.8;
    color: black;
  }
}

.ant-select-multiple .ant-select-selection-search {
  margin-inline-start: 0 !important;
  margin: 2px 0;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-select-single {
  height: auto !important;
}

// Raido group
.ant-radio-group {
  .ant-radio-wrapper {
    color: var(--color-white);
  }

  .ant-radio-input:focus+.ant-radio-inner {
    box-shadow: none;
  }

  .ant-radio {
    .ant-radio-inner {
      background-color: transparent;
      border-color: var(--color-60-black);
    }

    &.ant-radio-checked {
      .ant-radio-inner {
        border-color: var(--color-primary);
      }

      .ant-radio-inner::after {
        background-color: var(--color-primary);
      }
    }
  }

}