.s1xn8hf3{position:fixed;left:0;top:0;padding:16px 0 0 0;width:320px;height:100vh;color:black;background:var(--color-charcoal-surface-1);z-index:100;-webkit-transition:-webkit-transform 0.2s ease-in;-webkit-transition:transform 0.2s ease-in;transition:transform 0.2s ease-in;-webkit-transform:translateX(-100%);-ms-transform:translateX(-100%);transform:translateX(-100%);}.s1xn8hf3.show{z-index:1002;-webkit-transform:translateX(0);-ms-transform:translateX(0);transform:translateX(0);}@media (max-width:768px){.s1xn8hf3{box-shadow:0px 0 15px rgba(0,0,0,0.35);}}
.nnag3cp{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;margin-top:70px;height:calc(100vh - 90px);overflow-y:auto;position:relative;z-index:1;}
.n5t706g{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.e13txkra{color:var(--color-30-gray);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:end;-webkit-justify-content:end;-ms-flex-pack:end;justify-content:end;z-index:1;margin-top:12px;}
.nf64974{color:var(--color-white);font-size:1rem;font-weight:700;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;text-align:center;padding:0px 10px;}
.g1ofdsah{margin-top:1rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;text-align:center;}
.g8v8gxs{color:var(--color-white);margin-top:36px;padding:0 16px;}
.mtzj2nr{margin-top:36px;}
.t1gvehf2.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){background:var(--color-white) !important;color:var(--color-full-black) !important;border:1px solid var(--color-white);font-size:1rem;font-weight:500;border-right-color:var(--color-white);}.t1gvehf2.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,.t1gvehf2.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus{border-color:var(--color-white);outline:none;box-shadow:none;}.t1gvehf2 .ant-radio-button-wrapper{border:none;padding:4px 24px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;border:1px solid var(--color-white);background:transparent !important;color:var(--color-white) !important;border-right-color:var(--color-white) !important;}.t1gvehf2 .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{display:none;background:none;}.t1gvehf2.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{display:none;background-color:none !important;}.t1gvehf2 .ant-radio-button-wrapper:first-child{border-radius:0;border-color:var(--color-white);}.t1gvehf2 .ant-radio-button-wrapper:not(:first-child)::before{display:none;}.t1gvehf2 .ant-radio-button-wrapper:last-child{border-radius:0;}
.s14g00jy.ant-collapse{width:100%;border-radius:10px;margin-bottom:1rem;background:transparent;color:var(--color-white);border:0;}.s14g00jy.ant-collapse .ant-collapse-item .ant-collapse-header{color:var(--color-30-gray);padding:9px 16px !important;border-radius:0px !important;}.s14g00jy.ant-collapse > .ant-collapse-item{border:0;}.s14g00jy .ant-collapse-content{background:transparent;color:var(--color-white);padding:0;border-top:1px solid var(--color-60-gray);}.s14g00jy .ant-collapse-content > .ant-collapse-content-box{padding:0px;border-radius:0;}
.chq5vdw{max-height:201px;overflow-y:auto;padding:12px 0;}
.cnrt4ds{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;font-size:15px;font-weight:500;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:4px 16px;border-radius:0px;cursor:pointer;-webkit-transition:all 0.2s ease-in;transition:all 0.2s ease-in;}.cnrt4ds .anticon svg{display:inline-block;}.cnrt4ds div .anticon svg{display:inline-block;}.cnrt4ds:hover{background:var(--color-charcoal-surface-2);}.cnrt4ds:hover .anticon svg{display:inline-block;}
.aq9hxqz{background:var(--color-charcoal-surface-2);}
.ptn1ic3 .ant-popover-inner{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;border-radius:5px;}
.sogbnmj{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.d1mi1ddc{cursor:pointer;text-align:center;font-size:1rem;padding:0px 10px;text-align:center;color:var(--color-gale-gray);}
.o9pd2zi{position:fixed;top:0;left:0;width:100vw;height:100vh;z-index:1001;background:var(--color-full-black);opacity:0.3;}
.a1faks8w{color:var(--color-white);font-size:1rem;font-weight:700;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;text-align:center;padding:0px 16px;max-width:80%;margin:16px auto 0;}
.d1s096r5{color:var(--color-30-gray);z-index:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;margin-top:12px;text-align:center;}
.ufrqlxo{cursor:pointer;font-weight:600;margin-bottom:0.2rem;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;