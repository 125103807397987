:root {
    --color-primary: #DC5A4B;
    --color-primary-tint-90: #E06B5D;
    --color-60-gray: #666;
    --color-30-gray: #8E8E8E;
    --color-gale-gray: #DCDCDC;
    --color-almost-black: #191919;
    --color-charcoal-black: #2A2A2A;
    // tags txt color
    --color-tag-green-txt: #027A48;
    --color-tag-red-txt: #B42318;
    --color-tag-yellow-txt: #916800;
    --color-tag-orange-txt: #B54C00;
    --color-tag-blue-txt: #0055C3;
    --color-tag-purple-txt: #562CB7;
    --color-tag-gray-txt: rgba(0, 0, 0, 0.88);
    --color-tag-cyan-txt: #006d75;
    // tags border color
    --color-tag-green-border: #09AE69;
    --color-tag-red-border: #EA2F21;
    --color-tag-yellow-border: #FFC855;
    --color-tag-orange-border: #F09150;
    --color-tag-blue-border: #2785FF;
    --color-tag-purple-border: #8653FF;
    --color-tag-gray-border: rgb(217, 217, 217);
    --color-tag-cyan-border: #87e8de;
    // tags background color
    --color-tag-green-background: #ECFDF3;
    --color-tag-red-background: #FEF3F2;
    --color-tag-yellow-background: #FFFBDA;
    --color-tag-orange-background: #FFEDE0;
    --color-tag-blue-background: #EFF8FF;
    --color-tag-purple-background: #F2EAFF;
    --color-tag-gray-background: #fafafa;
    --color-tag-cyan-background: #e6fffb;
    // black scale
    --color-white: #ffffff;
    --color-10-black: #F4F4F4;
    --color-20-black: #E0E0E0;
    --color-30-black: #C6C6C6;
    --color-40-black: #8D8D8D;
    --color-50-black: #6F6F6F;
    --color-60-black: #525252;
    --color-70-black: #393939;
    --color-80-black: #262626;
    --color-90-black: #161616;
    --color-full-black: black;
    // token
    --feedback-dislike: #FD5C50;
    --feedback-like: #09AE69;
    --color-charcoal-surface-1: var(--color-90-black);
    --color-charcoal-surface-2: var(--color-80-black);
    --color-charcoal-surface-3: var(--color-70-black);
    --color-outlined-button: var(--color-primary);
    --color-link: var(--color-primary);
    --color-card-background: var(--color-white);
    --color-hover-black: #121212;
    --color-box-shadow: rgba(25, 25, 25, 0.04);
    --color-input-placeholder: #C4C4C4;
    --color-progress-bg: #C4C4C4;
    --color-border: #C4C4C4;
    --color-border-light: #D2D2D2;
    --color-border1: #D9D9D9;
    --color-light-grey-bg: #CCCCCC29;
    --color-lighter-grey: #E0E0E0;
    --color-card-grey-bg: #F6F6F6;
    --color-dark-grey-bg: #EAEAEA;
    --card-border-radius: 5px;
    --color-green: #9BBD14;
    --color-gale-yellow: #FFC855;
    --color-gale-orange: #F09150;
    --color-gale-blue: #0055C3;
    --color-section-seperators: #0000004d;
    --color-inner-border: #959595;
    --color-table-header: #626262;
    --color-table-header-background: #FAFAFC;
    --box-shadow-popover: 1px 4px 4px rgba(0, 85, 195, 0.25);
    /*New Constants*/
    --color-button-primary: #0055C3;
    --new-app-background-color: #f5f5f7;
    --color-cyan: #1890ff;
    --color-bright_blue: #6186ed;
}
