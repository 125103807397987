.audience-details-container {
    color: var(--color-white);
    padding: 20px;
}

.header {
    margin-bottom: 20px;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    text-align: left !important;
    color: var(--color-white);
}

.description {
    text-align: left !important;
    margin: 20px 0;
    font-size: 16px;
}

.tabs {
    margin-bottom: 20px;
}

.audience-size,
.base-size {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
    font-style: italic;
}

.audience-size {
    color: var(--color-30-gray) !important
}

.base-size {
    color: var(--color-30-gray) !important
}

.sql-query {
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: var(--color-dark-background);
    /* Dark background color */
    padding: 10px;
    border-radius: 4px;
    border: 1px solid var(--color-dark-border);
    /* Dark border color */
    color: var(--color-light-text);
    /* Light text color */
}

.breadCrumb {
    margin-bottom: 16px !important;
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;