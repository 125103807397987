.c15a9531{min-width:0;overflow:auto;}
.cnrob0d{background:var(--color-full-black);z-index:1;width:100%;overflow:auto;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}@media (max-width:992px){.cnrob0d{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}}
.lj1bxa7{position:relative;background:#000000;width:100%;height:100vh;min-height:100vh;min-height:100dvh;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.lol0x7z{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.mhmm9a1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;padding-top:20vh;position:relative;z-index:1;}@media (max-height:500px){.mhmm9a1{padding-top:32px;}}
.r1sahwm0{border-radius:10px;box-shadow:-3px 4px 8px rgb(41 29 74 / 15%);position:relative;width:50%;min-height:100vh;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:#fff;float:left;overflow:auto;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}@media (max-width:992px){.r1sahwm0{width:100%;}}
.r1pnv14v{position:absolute;text-align:center;bottom:10%;color:white;font-size:24px;font-weight:500;width:100%;}@media screen and (max-width:768px){.r1pnv14v{font-size:14px;}}
.m3wn98d{width:450px;}@media screen and (max-width:768px){.m3wn98d{width:250px;}}@media (max-height:500px){.m3wn98d{width:250px;}}
.fbw36vo{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.f17yhx7j{color:white;background-color:black;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;margin-bottom:1rem;font-size:1.1rem;font-weight:500;}
.d1d84qrr{pointer-events:none;position:fixed;bottom:0;left:0;width:100vw;height:100vh;z-index:0;}.d1d84qrr img{position:absolute;bottom:0;left:0;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;}@media screen and (min-width:768px){.d1d84qrr img{width:100%;object-fit:contain;}}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;