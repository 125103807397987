.l12yr5xm{position:relative;}
.t124gzds{font-size:20px;font-weight:600;margin-bottom:0.1rem;color:black;}
.h1y922om{padding:0.8rem;height:50px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;background-color:black;z-index:1000;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:-webkit-sticky;position:sticky;left:0;top:0;}
.c1pdkxjl{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100vw;overflow:hidden;margin-left:0;background-color:var(--color-charcoal-surface-2);}@media screen and (min-width:768px){.c1pdkxjl{width:calc(100vw - 320px);margin-left:320px;}}
.s3x1q4t{margin-top:0px !important;padding-top:32px !important;}
.mmd3ava{-webkit-flex:1;-ms-flex:1;flex:1;overflow-y:scroll;padding:1rem;padding-left:1.5rem;margin-top:0px;}@media screen and (min-width:768px){.mmd3ava{margin-top:5px;padding-bottom:32px;}}@media screen and (min-width:1200px){.mmd3ava{padding:0 15%;padding-bottom:32px;}}
.m1tlahac{position:fixed;top:60px;width:100vw;overflow:auto;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;height:calc(100vh - 50px);height:calc(100dvh - 50px);-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-transition:-webkit-transform 0.2s ease-in;-webkit-transition:transform 0.2s ease-in;transition:transform 0.2s ease-in;background:var(--color-charcoal-surface-2);}@media screen and (min-width:768px){.m1tlahac{top:0px;height:100vh;}}
.hxt7ffo{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;color:var(--color-white);-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;margin-top:1rem;font-size:1.429rem;font-weight:700;}
.hzskm63{margin:7vh 0;}@media screen and (max-width:768px){.hzskm63{margin:1rem 0;}}
.c1heesut{font-size:14px;font-weight:600;margin-top:0.3rem;}
.c1whu1z2{color:var(--color-white);padding-left:32px;font-weight:400;width:calc(100% - 3.4rem);margin-bottom:0.5rem;}

.i144m0p5{right:16%;position:absolute;top:30%;padding:4px 7px;border-radius:0.5rem;background:black;}@media screen and (max-width:768px){.i144m0p5{right:7%;}}
.ad5b3qw{padding:6px 9px;background:#788f9c;border-radius:26px;}
.n1org50c{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;height:100%;}
.c1mttq3o{-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column-reverse;-ms-flex-direction:column-reverse;flex-direction:column-reverse;}
.c1boxjo0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:8px;}
.m1ydzczl{font-weight:600;color:black;margin-bottom:0.2rem;font-size:16px;}
.r11eduz6{margin-top:0.2rem;font-size:1rem;}.r11eduz6 > *{opacity:0.7;-webkit-transition:-webkit-transform 0.2s;-webkit-transition:transform 0.2s;transition:transform 0.2s;}.r11eduz6 > *:hover{opacity:1;-webkit-transform:scale(1.1);-ms-transform:scale(1.1);transform:scale(1.1);}
.r10e38v2{cursor:pointer;padding-right:3px;}
.c13wmcc1{padding-right:3px;}
.m163b6uw{display:contents;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;color:#2c2c2c;font-weight:400;}.m163b6uw table{border-collapse:collapse;border-radius:5px;overflow:hidden;}.m163b6uw th{padding:6px 13px;background:black;color:white;font-weight:600;}.m163b6uw td{background:#eeeeee;padding:6px 13px;color:black;font-weight:400;}.m163b6uw p{font-size:1.143rem !important;margin-bottom:0.3rem;}
.rq0hk06{opacity:0.75;pointer-events:none;}
.c119bfmm{font-size:1rem;font-weight:600;cursor:pointer;}
.soyj8t8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:1rem;gap:6px;}
.s1tvlan8{font-size:1rem;font-weight:600;color:gray;overflow:auto;}
.a6zrxjg{font-weight:700;color:gray;white-space:pre;overflow:auto;max-height:350px;}
.osd45p4{font-weight:600;font-size:0.85rem;margin-top:0.5rem;}
.i1ecuh9p{font-weight:600;font-size:0.85rem;}
.a1szcuk8{background:#f9f9f9;padding:5px 10px;border-radius:5px;}
.u10rfatr{font-size:1.1rem;margin-bottom:1rem;font-weight:600;cursor:pointer;}
.a2yww6u{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;cursor:pointer;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:10px;margin-right:12px;}.a2yww6u span{font-size:1rem;font-weight:500;}
.fi3o8t8{color:white;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;font-size:1.1rem;font-weight:500;width:100%;padding:1rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;background-color:black;z-index:1000;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:absolute;bottom:0;}
.s1v5d6tl{position:fixed;left:0;top:0;padding:16px;width:320px;height:100vh;color:black;background:#212121;z-index:2;-webkit-transition:all 0.2s ease-in;transition:all 0.2s ease-in;}
.qpa4hyk{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;width:100%;z-index:100;-webkit-transition:all 0.2s ease-in;transition:all 0.2s ease-in;}.qpa4hyk .ant-card-body{background:var(--color-charcoal-surface-1);border-bottom:1px solid var(--color-charcoal-black);}.qpa4hyk .ant-card-bordered{width:100%;border-radius:0;border:none;}.qpa4hyk .ant-tabs-tab:hover{color:var(--color-white);}.qpa4hyk .ant-tabs-nav::before{border-bottom:1px solid var(--color-60-gray) !important;}.qpa4hyk .ant-tabs{padding-bottom:8px;color:var(--color-white);font-size:13px;font-weight:400;}.qpa4hyk .ant-tabs h3{color:var(--color-white);}.qpa4hyk .ant-tabs-content-holder{max-height:0;overflow:hidden;-webkit-transition:all 0.4s ease-in;transition:all 0.4s ease-in;}.qpa4hyk .ant-btn-link{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:0.857rem;color:var(--color-40-black);padding:0px;}.qpa4hyk .ant-btn-link:hover{color:var(--color-white);}.qpa4hyk .ant-btn-link .more-info-icon{margin-right:4px;}.qpa4hyk.expanded .ant-tabs-content-holder{max-height:1000px;}@media screen and (min-width:768px){.qpa4hyk{width:calc(100% - 320px);margin-left:320px;}.qpa4hyk .ant-card-body{padding:50px 15% 24px;}}
.qfbp1xk{cursor:pointer;position:absolute;bottom:0px;right:16px;background:transparent;border:none;width:40px;height:40px;z-index:1;padding:0;-webkit-transform:rotate(0deg);-ms-transform:rotate(0deg);transform:rotate(0deg);-webkit-transition:all 0.2s ease-in;transition:all 0.2s ease-in;color:var(--color-white);}.qfbp1xk:hover{color:var(--color-primary);}.qfbp1xk.expanded{-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg);}@media screen and (min-width:768px){.qfbp1xk{right:14%;}}
.od0ebgv{position:absolute;top:50%;}
.nlmm9c9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.nk5xxf1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;height:calc(100vh - 102px);overflow-y:auto;}
.fd58jhb{font-size:16px;font-weight:600;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.f1toypng{padding-bottom:0.3rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.f1r2flx7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;padding:0.7rem 1rem;border-radius:6px;color:white;background:#484545;}
.p9dfkrd{top:64%;}
.p10v5jxk{top:61%;}
.s108hko{width:100%;max-height:30rem;overflow-y:auto;padding-right:0.5rem;padding-left:0.5rem;}
.lfmriun{opacity:0.8;pointer-events:none;cursor:not-allowed;}
.exe4ot6{background:#db5a4b !important;color:white !important;border:0 !important;font-weight:600 !important;height:2.2rem !important;padding-left:0.5rem !important;padding-right:0.5rem !important;}
.hmfyhbq > *{visibility:hidden;}
.t1cocq4m{float:right;visibility:unset;color:#1c1c1c;font-size:0.7rem;}
.t13livta{border-radius:0.5rem;padding-bottom:0.5rem !important;}.t13livta .ant-tooltip-content{border-radius:0.5rem;font-size:10px;}.t13livta .ant-tooltip-content .ant-tooltip-inner{border-radius:0.5rem;margin:5px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.s5xpiu8 .ant-skeleton-title{display:none;}.s5xpiu8 .ant-skeleton-paragraph{margin-top:0;}.s5xpiu8 li{height:10px !important;}.s5xpiu8 .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph{margin-top:1rem;}
.gpxwq8d{margin-top:1rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;text-align:center;}
.cpwed9n{width:70%;height:100%;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;overflow:hidden;-webkit-align-self:center;-ms-flex-item-align:center;align-self:center;}.cpwed9n .ant-skeleton{padding:11px;}.cpwed9n .ant-skeleton .ant-skeleton-content .ant-skeleton-title{width:20% !important;}
.s1dovfil{height:90%;margin-left:320px;}@media (max-width:768px){.s1dovfil{margin-left:0px !important;}}
.d1upha1r{font-size:1rem;padding:0px 10px;}
.twqx067.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){background:#171717 !important;}.twqx067 .ant-radio-button-wrapper{border:none;}.twqx067 .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{background:none;}.twqx067.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{background-color:none !important;}.twqx067 .ant-radio-button-wrapper:first-child{border-radius:5px 0 0 5px;}.twqx067 .ant-radio-button-wrapper:last-child{border-radius:0 5px 5px 0;}
.n1298gfu{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;text-align:center;padding:0px 10px;}
.c1m9ftpz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;font-size:15px;font-weight:500;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:5px 10px;border-radius:4px;cursor:pointer;}.c1m9ftpz .anticon svg{display:inline-block;}.c1m9ftpz div .anticon svg{display:inline-block;}.c1m9ftpz:hover{background:#e0e0e0;}.c1m9ftpz:hover .anticon svg{display:inline-block;}
.a1yx5gd9{background:#e0e0e0;}
.s1cq91vz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.p150fkoa .ant-popover-inner{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;border-radius:5px;}
.c89qjr7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.c89qjr7 #hoverImage{display:none;}.c89qjr7:hover #normalImage{display:none;}.c89qjr7:hover #hoverImage{display:inline-block;}
.l2d78tl{position:relative;top:10px;left:350px;z-index:999999 !important;overflow:auto !important;max-width:200px;}
.c18glyrb .ant-dropdown-menu{background-color:var(--color-charcoal-surface-1) !important;color:var(--color-white) !important;}.c18glyrb .ant-dropdown-menu-submenu-arrow-icon{color:inherit !important;}.c18glyrb.ant-dropdown-menu-submenu-popup{-webkit-inset:44px auto auto 480px !important;-ms-inset:44px auto auto 480px !important;inset:44px auto auto 480px !important;border-radius:8px !important;}@media (max-width:768px){.c18glyrb.ant-dropdown-menu-submenu-popup{inset:104px auto auto 160px !important;}}.c18glyrb.ant-dropdown-menu-submenu-popup ul li{margin-bottom:0px !important;}
@media screen and (max-width:768px){.t1f5ngmj{width:100% !important;}.t1f5ngmj .ant-modal-body{padding:16px 0px !important;}}@media screen and (max-width:390px){.t1f5ngmj .ant-row{display:block !important;}.t1f5ngmj .ant-col{margin-bottom:24px;max-width:100% !important;}.t1f5ngmj .ant-col:last-child{margin-bottom:0;}}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;