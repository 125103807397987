.audience-listing-container.dark-theme {
    .list-header {
        display: flex;
        justify-content: space-between;
        background-color: var(--color-charcoal-black);
        padding: 8px 16px;
        color: var(--color-white);
        font-weight: bold;
        font-size: 0.9em;

        @media (max-width: 768px) {
            display: none; // Hide column headers on mobile
        }
    }

    .header-column {
        flex: 1; // Equal spacing for each column
        text-align: left;

        &:nth-child(1) {
            flex: 2; // Make the "Name" column wider
        }
    }

    .audience-list-item {
        border-bottom: none !important;
    }

    .collapse-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 768px) {
            flex-direction: column; // Stack the elements on mobile
            align-items: flex-start;
        }
    }

    .header-title {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 768px) {
            flex-direction: column; // Stack items vertically on mobile
            gap: 4px;
        }

        .item-name {
            flex: 2;
            font-weight: bold;
            color: var(--color-white);

            @media (max-width: 768px) {
                width: 100%; // Full width for mobile
            }
        }

        .item-type,
        .item-created-by,
        .item-created-on {
            flex: 1;
            font-size: 0.85em;
            color: var(--color-60-gray);

            @media (max-width: 768px) {
                width: 100%; // Full width for mobile
            }
        }
    }

    .dropdown-icon {
        color: var(--color-primary);
        cursor: pointer;
    }

    .ant-collapse {
        background-color: var(--color-charcoal-surface-2);
        border-color: var(--color-charcoal-surface-3);

        .ant-collapse-header {
            color: var(--color-white);
            font-weight: 600;
        }

        .ant-collapse-content {
            background-color: var(--color-charcoal-surface-2);
            border-color: var(--color-charcoal-surface-3);

            .ant-collapse-content-box {
                background-color: var(--color-charcoal-surface-2);
                padding: 16px; // Add padding for better spacing
            }
        }
    }

    .ant-pagination {
        margin-top: 24px;

        .ant-pagination-item {
            background-color: var(--color-charcoal-surface-2);
            border-color: var(--color-charcoal-surface-3);

            a {
                color: var(--color-white);
            }
        }

        .ant-pagination-item-active {
            background-color: var(--color-charcoal-surface-3);
            border-color: var(--color-charcoal-surface-1);

            a {
                color: var(--color-white);
            }
        }

        .ant-pagination-prev,
        .ant-pagination-next {
            .ant-pagination-item-link {
                background-color: var(--color-charcoal-surface-2);
                border-color: var(--color-charcoal-surface-3);
                color: var(--color-white);
            }
        }
    }
}

.listing-table.ant-table-wrapper {
    .ant-tag {
        color: #000;
    }

    .ant-btn-default {
        color: #fff !important;
        padding: 0 8px !important;
    }

    .ant-table {
        background-color: transparent;

        .ant-table-container {
            .ant-table-thead>tr>th {
                background-color: transparent;
                color: var(--color-default-text-color);
                font-weight: 500;
                font-size: 14px;
                text-align: left;

                @media (max-width: 768px) {
                    font-size: 12px;
                }

                @media (max-width: 480px) {
                    font-size: 10px;
                }
            }

            .ant-table-tbody>tr>td {
                background-color: #262626;
                color: var(--color-default-text-color);
                font-weight: 400;
                font-size: 14px;
                text-align: left;
                border-bottom: 8px solid #000;

                @media (max-width: 768px) {
                    font-size: 12px;
                }

                @media (max-width: 480px) {
                    font-size: 10px;
                }
            }

            .ant-table-cell {
                padding: 8px 16px;
            }
        }
    }

    .ant-spin-nested-loading {
        .ant-spin-nested-loading .ant-spin {
            color: #DC5A4B;
        }
    }
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;