.demographic-overview-section {
    color: var(--color-black);
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;



.core-demographics-container {
    background-color: var(--color-charcoal-surface-2);
    margin-top: 16px;
    padding: 6px;
    padding-bottom: 0px;
    border-radius: 8px;
}

.section-title__demographic {
    font-size: 1.75em;
    color: var(--color-30-black) !important;
    text-align: center;
}

.core-charts-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* Allow wrapping on smaller screens */
}

.core-chart-container {
    flex: 1;
    margin-right: 6px;
    border-radius: 8px;
    padding: 4px;
    min-width: 300px;
    padding-top: 12px;
    margin-bottom:6px;
    padding-bottom: 6px;
    background-color: black;
}

.core-chart-select {
    display: flex;
    justify-content: flex-end;
    background-color: black;
    width: 100%;
    padding: 10px;
    border-radius: 8px 8px 0 0;
}

.core-chart {
    width: 100%;
    height: 400px !important;
}

.core-chart-container:last-child {
    margin-right: 0;
}

.gender-container {
    flex: 0 0 25%;
    margin-right: 4px;
    border-radius: 8px;
    padding: 4px;
}

.gender-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.gender-icon {
    text-align: center;
}

.gender-icon svg {
    display: block;
    margin: 0 auto;
}

.gender-icon p {
    font-size: 16px;
    color: #ddd;
}

.section-title {
    margin-top: 8px;
    font-weight: 500;
    color: var(--color-30-black) !important;
    text-align: center;
}

.charts-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}

.chart-container {
    flex: 1;
    min-width: 300px;
    max-width: 1200px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chart {
    width: 100%;
    height: 400px;
}

.one-chart-row .core-chart-container {
    flex: 1 1 100%;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {

    .core-chart-container,
    .family-container,
    .gender-container,
    .one-chart-row .core-chart-container {
        flex: 1 1 100%;
        margin-right: 0;
    }

    .core-charts-row {
        flex-direction: column;
        gap: 10px;
    }

    .chart-container {
        min-width: 100%;
    }
}
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;