.w32p7d9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;padding:48px 24px;}
.wqersqo{text-align:center;margin-bottom:48px;}
.w5nupnq{font-size:32px;font-weight:600;color:var(--color-white);margin-bottom:16px;}
.w1mf5kxj{font-size:16px;color:var(--color-60-gray);max-width:600px;margin:0 auto;}
.f1d67agl{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;grid-template-columns:repeat(auto-fit,minmax(280px,1fr));gap:24px;width:100%;max-width:1200px;margin-bottom:48px;}@media (max-width:768px){.f1d67agl{display:block;}}
.f17lahaj{background-color:var(--color-90-black);padding:24px;border-radius:8px;border:1px solid var(--color-80-black);-webkit-transition:border-color 0.3s ease;transition:border-color 0.3s ease;-webkit-flex:1;-ms-flex:1;flex:1;}
.f1cndu9l{font-size:24px;color:var(--color-link);margin-bottom:16px;}
.f1a6dkt0{font-size:18px;font-weight:500;color:var(--color-white);margin-bottom:8px;}
.fkktlpu{font-size:14px;color:var(--color-60-gray);line-height:1.5;}
.c21y5g1{height:40px;padding:0 24px;font-size:16px;}
.a16t2h6y{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:20px;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;}@media (max-width:768px){.a16t2h6y{display:contents;}}
.adnuy2r{display:block;margin:30px auto;width:17px;height:17px;border-top:2px solid var(--color-40-black);border-left:2px solid var(--color-40-black);-webkit-transform:rotate(135deg);-ms-transform:rotate(135deg);transform:rotate(135deg);}.adnuy2r::before{content:"";display:block;width:2px;height:32px;background-color:var(--color-40-black);-webkit-transform:rotate(-45deg) translate(10px,4px);-ms-transform:rotate(-45deg) translate(10px,4px);transform:rotate(-45deg) translate(10px,4px);left:0;top:0;}@media (max-width:768px){.adnuy2r{margin:30px auto 20px;-webkit-transform:rotate(225deg);-ms-transform:rotate(225deg);transform:rotate(225deg);}}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;