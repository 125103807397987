
    .tagPopover {
        .ant-popover-inner-content {
            padding: 5px;
            box-shadow: 0px 0px 1px 0px #ffffff8a;
          }
          .ant-popover-arrow > .ant-popover-arrow-content {
            box-shadow: 0px 0px 1px 0px #ffffff8a;
          }
    }
    
    .tagWrapper {
        font-size: 12px;
        color: white;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: fit-content;
        max-height: 200px;
        max-width: 268px;
        overflow-y: auto;
    }
    
    .tagText {
        color: black;
        background-color: white;
        border-radius: 3px;
        margin: 5px;
        border: solid 0.5px #c6c6c685;
        padding: 3px;
        padding-top: 4px;
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
    }

    .tagTextDisabled { 
        opacity: 0.8;
    }



@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;