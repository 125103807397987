.float-label {
  position: relative;
  .label.as-placeholder , label.as-label{
    font-weight: 400;
    z-index: 1;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    line-height: 45px;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: var(--color-primary-tint-90) !important;
  }

  .ant-select-focused {
    border: 0 !important;
  }

  .ant-input-number-group-wrapper-outlined.ant-input-number-group-wrapper .ant-input-number-group-addon {
    border: 1px solid var(--color-60-black) !important;
    color: white;
    border-radius: 0;
  }

  .ant-input,
  .ant-select-selector,
  .ant-input-password {
    border: 1px solid #696969 !important;
    height: 40px !important;
    border-radius: 4px;
  }

  .ant-input-password{
    #password , #repassword{
      border: none !important;
      height: 100% !important;
    }
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-focused {
    box-shadow: none !important;
    border-radius: 4px;

    &:focus {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border-color: transparent;
    }

    &:hover {
      box-shadow: none;
      border-color: transparent;
    }
  }
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 11px;
  transition: 0.2s ease all;
}

.as-placeholder {
  color: gray;
}

.as-label {
  top: -8px;
  font-size: 12px !important;
  background: var(--color-full-black);
  padding: 0 4px;
  margin-left: -4px;
}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;