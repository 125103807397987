.chooseLlmModelContainer {
  .ant-cascader-menus {
    max-height: 200px !important;
    overflow-y: auto;
  }

  .ant-cascader-menu-item-active {
    background-color: black !important;
    color: white !important;
  }
}

.modelLLmDropdown{

  display: 'flex';
  background-color: 'black';

  .ant-cascader-menus {
      background-color: rgba(50, 50, 50, 0.9) !important;
      padding: 4px;
      min-width: fit-content !important;
  }

  .ant-cascader-menu:first-child {
    max-height: 200px !important;
    height: fit-content !important;
    width: fit-content !important;
    min-width: 150px !important;
    overflow-y: auto !important;
    background-color: transparent !important;
  }

  .ant-cascader-menu:nth-child(2) {
    height: 160px !important;
    width: fit-content !important;
    max-height: 200px !important;
    min-width: 200px !important;
    overflow-y: auto !important;
    background-color: var(--color-charcoal-surface-1) !important;
  }

  .ant-cascader-menu-item {
      color: gray !important; 
  }

  .ant-cascader-menu-item-active {
      background-color: var(--color-40-black) !important;
      color: white !important;
      font-weight: 400;
      font-size: 1.143rem !important; 
      .ant-cascader-menu-item-expand-icon{
          color: white !important;
      }
  }

  .ant-cascader-menu-item:hover {
      background-color: var(--color-60-black) !important;
      color: white !important;
      .ant-cascader-menu-item-expand-icon{
          color: white !important;
      }
  }
  .ant-cascader-menu-item-expand-icon{
      color: gray !important;
  }
  .ant-cascader-menu-item-expand-icon:hover{
      color: white !important;
  }
  span.ant-select-selection-item{
      font-size: 1.143rem !important;  
  }
  .ant-cascader-menu, 
  .ant-cascader-menu-item, 
  .ant-cascader-menu-item-active, 
  .ant-cascader-menu-item:hover, 
  .ant-cascader-picker, 
  .ant-cascader-picker-label {
      font-size: 1.143rem !important; 
      font-family: inherit !important;
      color: white !important;
  }

  .ant-select-customize-input .ant-select-selector .ant-select-selection-item {
      font-size: 1.143rem !important;
      font-family: inherit !important;
      color: white !important;
  }

  .ant-select-selector {
      width: auto !important;
      min-width: 150px !important; 
      max-width: 100% !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
  }
    
}
  
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;