.c1shslpc{margin-bottom:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.s1r8tvd8{width:100%;}
.aqrs8v8.ant-card{background-color:var(--color-charcoal-surface-2);border:none;box-shadow:none;height:100%;overflow-y:auto;border-radius:10px !important;max-width:1440px;margin:0 auto;width:100%;padding:0;}.aqrs8v8 .ant-card-body{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:normal;-webkit-box-align:normal;-ms-flex-align:normal;align-items:normal;}
.t1m6feiu{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:0;text-align:left;width:100%;box-sizing:border-box;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;}
.k1a1d6pw .ant-select-selection-placeholder{font-size:0.9rem !important;}.k1a1d6pw .ant-select.ant-select.ant-select{padding:0 !important;}.k1a1d6pw .ant-select.ant-select.ant-select:hover{padding:0 !important;}.k1a1d6pw .ant-select.ant-select.ant-select .ant-select-selector.ant-select-selector.ant-select-selector{padding-top:7px !important;padding-bottom:7px !important;}.k1a1d6pw .ant-select.ant-select.ant-select .ant-select-selector.ant-select-selector.ant-select-selector:hover{padding-top:7px !important;padding-bottom:7px !important;}.k1a1d6pw .ant-select.ant-select.ant-select .ant-select-selector.ant-select-selector.ant-select-selector .ant-select-selection-overflow{padding:0;}.k1a1d6pw .ant-select.ant-select.ant-select .ant-select-selector.ant-select-selector.ant-select-selector .ant-select-selection-overflow .ant-select-selection-item-content{font-size:0.8rem;}.k1a1d6pw .ant-input.ant-input,.k1a1d6pw .ant-picker-input.ant-picker-input.ant-picker-input{padding-top:9px !important;padding-bottom:9px !important;font-size:1.1rem !important;}.k1a1d6pw .ant-picker.ant-picker-range .ant-picker-input input{font-size:0.8rem !important;text-align:center;}
.k41thbr{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;z-index:3;width:100%;background-color:var(--color-charcoal-surface-1);border-radius:10px;padding:10px 0px;}.k41thbr .ant-col{padding:5px 10px !important;}.k41thbr .ant-col.col-wrapper{padding:5px 0px !important;width:49%;}
.tvui9vv{font-size:12px;color:white;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;max-height:200px;max-width:268px;overflow-y:auto;}
.tl54ztd{color:black;background-color:white;border-radius:3px;margin:5px;border:solid 0.5px #c6c6c685;padding:3px;padding-top:4px;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.t14byjcc .ant-popover-inner-content{padding:5px;box-shadow:0px 0px 1px 0px #ffffff8a;}.t14byjcc .ant-popover-arrow > .ant-popover-arrow-content{box-shadow:0px 0px 1px 0px #ffffff8a;}
.sthhqjx .ant-select-item-option-content{font-size:0.8rem;word-wrap:normal;text-overflow:unset;}
.sr4uiim{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:8px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:white;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:8px;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;height:58px;padding-bottom:13px;}.sr4uiim .ant-btn-primary{padding:10px !important;font-size:1rem;}.sr4uiim .ant-select .ant-select-selector.ant-select-selector{padding:4px 8px !important;}.sr4uiim .ant-select .ant-select-selector.ant-select-selector:hover{padding:4px 8px !important;}.sr4uiim .ant-select .ant-select-selection-item.ant-select-selection-item.ant-select-selection-item{font-size:0.8rem !important;}.sr4uiim .ant-select .ant-select-selection-item.ant-select-selection-item.ant-select-selection-item:hover{font-size:0.8rem;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;