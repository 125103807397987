.cim6jkf{width:100%;gap:8px;margin-bottom:24px;}@media screen and (min-width:768px){.cim6jkf{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;}}
.c9anvdb{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column-reverse !important;-ms-flex-direction:column-reverse !important;flex-direction:column-reverse !important;}@media screen and (min-width:768px){.c9anvdb{-webkit-flex-direction:row !important;-ms-flex-direction:row !important;flex-direction:row !important;}}
.ssf49tc{overflow-y:auto;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:40vh;-webkit-flex:0 0 auto;-ms-flex:0 0 auto;flex:0 0 auto;-webkit-flex-basis:25%;-ms-flex-preferred-size:25%;flex-basis:25%;background:var(--color-charcoal-surface-1) !important;}@media screen and (min-width:768px){.ssf49tc{height:calc(100vh - 250px);}}
.s1si1kg2{overflow-y:auto;height:40vh;display:-webkit-box !important;display:-webkit-flex !important;display:-ms-flexbox !important;display:flex !important;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex:0 0 auto;-ms-flex:0 0 auto;flex:0 0 auto;-webkit-flex-basis:75%;-ms-flex-preferred-size:75%;flex-basis:75%;}@media screen and (min-width:768px){.s1si1kg2{height:calc(100vh - 250px);}}
.vvbmbu1{width:1px;background-color:#ccc;height:100%;}
.c13pg3jb{height:100%;padding:20px;}.c13pg3jb .ant-card-bordered{border:solid 1px lightgray;border-radius:8px;box-shadow:5px 5px 5px lightgray;margin-bottom:16px;}
.bzmosww{margin-bottom:16px !important;}
.cf2fb7f{border-radius:16px;background:var(--color-charcoal-surface-1) !important;color:var(--color-white) !important;}.cf2fb7f.ant-card{height:100%;}.cf2fb7f .ant-card-head-wrapper{-webkit-align-items:normal;-webkit-box-align:normal;-ms-flex-align:normal;align-items:normal;}.cf2fb7f .ant-card-body{padding-top:8px;border-bottom:1px solid var(--color-60-black);}.cf2fb7f:last-child .ant-card-body{border-bottom:none;}.cf2fb7f .ant-card-head{border-bottom:none;}.cf2fb7f .ant-card-head-title{color:var(--color-white) !important;padding-bottom:0px;font-weight:700;}
.c11kzejn{width:100%;height:inherit;min-height:500px;max-height:600px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;overflow:hidden;}
.cs23fth{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin-top:1rem;}
.h11owqgh{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1.5rem;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:1rem;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;