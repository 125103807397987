.ant-drawer-content-wrapper {
  .ant-drawer-wrapper-body {
    position: relative;
    padding:40px !important;
    
    .ant-drawer-header {
      padding:0;
      border-bottom: none;
    }
  }
  .ant-drawer-content{
    background-color: var(--color-charcoal-surface-2);
    color:var(--color-white);
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse !important;
    .ant-drawer-close {
      margin-right:0 !important;
      color:var(--color-white);
      &:hover {
        color:var(--color-primary);
      }
    }
    .ant-drawer-title {
      font-size: 1.429rem;
      font-weight: 700;
      color:var(--color-white);
    }
  }

  .ant-drawer-body {
    padding:32px 32px;
    overflow-x:hidden;
  }
  
}