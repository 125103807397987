.cu65juu{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:16px;}
.o14o55vf{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;width:100%;}
.ocq25uc{padding:0 !important;}
.sjihblz{position:absolute;top:-11px;right:-11px;font-size:18px;cursor:pointer;color:white;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.o19jkm7f{font-size:12px;color:white;padding-top:12px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;}
.oloa3jc .ant-list-item.ant-list-item-no-flex{border:None;}
.nq4riha{color:white;margin-bottom:8px;padding:5px;border-radius:5px;margin:5px;margin-left:0;margin-right:10px;background-color:#3c3b3b;}
.r1jt4yiq.ant-ribbon.ant-ribbon-placement-start{top:-29px;left:-34px !important;}
.stxt8o6{font-size:18px;font-weight:600;padding-bottom:10px;}
.vfhmmvo{-webkit-box-pack:right;-webkit-justify-content:right;-ms-flex-pack:right;justify-content:right;position:absolute !important;bottom:24px !important;left:24px !important;font-size:18px;}.vfhmmvo.ant-btn.ant-btn-default{padding:5px 10px !important;}
.o1nc3u6l{padding:0px 16px;}
.l6ny9w3{font-size:16px;}.l6ny9w3.anticon.anticon:hover{color:green;}
.djxa1r6{font-size:16px;}.djxa1r6.anticon.anticon:hover{color:red;}
.phewlcs{font-size:20px;cursor:pointer;}.phewlcs .anticon:hover{color:#40a9ff;}
.fcepsuw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;}
.fqicude{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:20px;}
.j192eb3e{height:100%;width:230px;}
.fu7lndz{height:100%;width:210px;margin-right:20px !important;}
.s281ras{height:52px;margin-right:20px !important;margin-bottom:0 !important;width:270px;}.s281ras .ant-input-wrapper.ant-input-group{height:100%;}.s281ras .ant-input-wrapper.ant-input-group .ant-input{height:100%;}
.s1aiqhf7{height:100%;width:255px;margin-right:20px !important;}
.oomjfjk{text-align:center;padding:5px 10px;border-radius:5px;box-shadow:inset #ffffff33 0px 0 8px -2px;font-size:12px;}
.dx9g0ck{width:100%;}.dx9g0ck table{border-color:white;}.dx9g0ck .ant-table-thead{background-color:#171717 !important;}.dx9g0ck .ant-table-thead .ant-table-cell::before{background-color:transparent !important;}.dx9g0ck .ant-table-body{background-color:#222222;}.dx9g0ck table > thead > tr > th{background-color:#171717 !important;color:white !important;}.dx9g0ck table > thead > tr > th.ant-table-cell-scrollbar{box-shadow:none;}.dx9g0ck table > tbody > tr > td{background-color:#222222 !important;color:white;border-bottom:none !important;}.dx9g0ck .ant-empty-description{color:white;}.dx9g0ck .ant-pagination li{border:0px;background-color:#222222;color:white;}.dx9g0ck .ant-pagination li button{background-color:#222222;color:white;border:none;}.dx9g0ck .ant-pagination li button:hover{background-color:#222222;color:white;}.dx9g0ck .ant-pagination li a{border:0px;background-color:#222222;color:white;}.dx9g0ck .ant-pagination-item-active{border:1px solid white !important;}
.a135zfic{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.apz28iv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.d1v52foi{padding-left:10px;}
.s1bu592x .ant-select-item.ant-select-item-option{padding-left:0;padding-right:0;}
.c1mfgqwo{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:3px 0;background:inherit;font-size:13px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;background-color:var(--color-charcoal-surface-3);-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.sgj23zr .ant-popover-inner-content.ant-popover-inner-content{max-width:300px !important;max-height:200px !important;overflow-y:auto !important;}
.s1ja12pm{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}
.f13lws4q{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;padding-left:10px;}
.f1ud6ayx{text-overflow:ellipsis;max-width:160px;white-space:nowrap;overflow:hidden;font-size:13px;}
.f198ei8y{font-size:10px;}
.u1bio44l{padding-left:10px;-webkit-align-self:center;-ms-flex-item-align:center;align-self:center;}
.ca6ga5r.ant-btn-primary{height:100%;}
.eysc4zt.ant-btn-primary{height:100%;}
.p1p3v5j5{background-color:#222222;}.p1p3v5j5 .ant-table-row{border:solid 1px black;}.p1p3v5j5 .ant-table-cell{padding:1px 8px;padding-right:0;color:black;border:none !important;font-size:10px;}
.pmzoxk9{padding:0;}.pmzoxk9 .ant-popover-inner-content.ant-popover-inner-content{max-width:300px !important;max-height:200px !important;overflow-y:auto !important;padding:0;}
.ptfmkly{max-width:190px;max-height:3rem;overflow-y:auto;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;