.tvdarz1{max-width:1440px;width:80%;margin:0 auto;padding:32px 0;}
.t118iwlg{color:var(--color-white);margin-bottom:24px;font-size:24px;font-weight:600;}
.t1krept{margin-bottom:24px;background-color:var(--color-90-black);border-radius:8px;padding:24px;}
.t1hdlrrw{color:var(--color-white);margin-bottom:16px;font-size:18px;font-weight:600;}
.t19rxx0p{display:grid;grid-template-columns:minmax(150px,auto) 1fr;gap:12px 24px;-webkit-align-items:baseline;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;}.t19rxx0p .ant-typography{color:var(--color-white);font-size:14px;}.t19rxx0p .ant-typography strong{color:var(--color-30-gray);}
.tae8cob{display:inline-block;cursor:pointer;color:var(--color-link);margin-bottom:16px;}.tae8cob .ant-btn-link{color:var(--color-link);padding-left:0;}.tae8cob .ant-btn-link:hover{color:var(--color-primary-tint-90);}
.tqj5anu{color:var(--color-white);font-size:18px;text-align:center;margin-top:50px;}
.e1sbh5t7{color:var(--feedback-dislike);}.e1sbh5t7:hover .ant-table-cell.ant-table-cell,.e1sbh5t7:hover td{color:var(--feedback-dislike) !important;}
.t4knnz5 .ant-tooltip-inner{width:-webkit-max-content;width:-moz-max-content;width:max-content;max-width:1000px;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;