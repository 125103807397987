.c1te49du{min-width:9.7143rem;}
.s17lptvl.ant-btn{font-weight:600;}
.u11w9g6n span{-webkit-text-decoration:underline;text-decoration:underline;}
.u1joii7u span{text-transform:uppercase;}
.m16p1jer.ant-btn{box-shadow:0px 4px 8px rgba(41,29,74,0.15);border-radius:5px;}
.m151irjx.ant-btn{background:#0055C3;border-color:#0055C3;}.m151irjx.ant-btn:hover,.m151irjx.ant-btn:focus,.m151irjx.ant-btn:active{background:#1b79f1;border-color:#1b79f1;}
.m81dr6w.ant-btn{color:#0055C3;}.m81dr6w.ant-btn:hover,.m81dr6w.ant-btn:focus,.m81dr6w.ant-btn:active{color:#1b79f1;}
.m8ybd0s.ant-btn{color:#0055C3;border-color:#0055C3;}.m8ybd0s.ant-btn:hover,.m8ybd0s.ant-btn:focus,.m8ybd0s.ant-btn:active{color:#1b79f1;border-color:#1b79f1;}
.tansjpl.ant-btn{background-color:transparent;padding:0;}.tansjpl.ant-btn:active,.tansjpl.ant-btn:hover,.tansjpl.ant-btn:focus,.tansjpl.ant-btn:active,.tansjpl.ant-btn:focus{background:none;}.tansjpl.ant-btn[disabled]{border:0;}


.lhsi121.ant-btn{padding:0;height:auto;}
.r1urvoza.ant-btn-primary{border-radius:5px;background:#0055c3;}
.r177ev39.ant-btn-primary{border-radius:15px;background-color:#fafafc;border-color:#fafafc;color:var(--color-almost-black);}.r177ev39.ant-btn-primary:active,.r177ev39.ant-btn-primary:focus,.r177ev39.ant-btn-primary:hover{background-color:#70a7c8;border-color:#70a7c8;color:var(--color-almost-black);}
.r1vdao40.ant-btn-primary{border-radius:15px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.oj04is7.ant-btn{border-color:var(--color-outline-button-color);color:var(--color-outlined-button);background-color:var(--color-card-background);}
.r1utb73n.ant-btn{border-radius:5px;}
.r14uvwgz.ant-btn{border-radius:15px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.s1q6cz34.ant-btn{box-shadow:0px 0px 14px 0px rgb(0 0 0 / 10%);padding:1.2rem 2rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;background-color:var(--color-card-background);}.s1q6cz34.ant-btn:hover,.s1q6cz34.ant-btn:focus,.s1q6cz34.ant-btn:active{background-color:inherit;box-shadow:0px 0px 14px 0px rgb(0 0 0 / 20%);}
.iqql9fe.ant-btn{background-color:transparent;padding:0.5rem;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;