.dark-table {
  overflow: auto;
  .ant-table-content {
    
    .ant-table-thead {
      .ant-table-cell {
        background-color: pink;
        &::before {
          background-color: transparent !important;
        }
      }
    }
  }

  .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: var(--color-90-black) !important;
  }

  table {
    border-color: white;
  }

  table>thead>tr>th {
    background-color: #171717 !important;
    color: white !important;
    border-radius: 0 !important;

    .ant-table-column-sorters {
      color: white;

      .ant-table-column-sorter-up,
      .ant-table-column-sorter-down {
        color: white;
      }
    }
  }

  ;

  table>tbody>tr>td {
    background-color: #222222 !important;
    color: white !important;
    border-bottom: none !important;
  }

  ;

  .ant-empty-description {
    color: white !important;
  }

  .ant-table-thead>tr>th.ant-table-column-has-sorters.ant-table-column-sort {
    background-color: #000000 !important;
  }
}

.dark-table-listing {
  overflow-x: auto;
  /* Ensure horizontal overflow is handled */
  .ant-table-content {
    .ant-table-thead {
      .ant-table-cell {
        background-color: pink;
        &::before {
          background-color: transparent !important;
        }
      }
    }
  }
  table>thead>tr>th {
    background-color: var(--color-90-black) !important;
    color: white !important;

    .ant-table-column-sorters {
      color: white !important;

      .ant-table-column-sorter-up,
      .ant-table-column-sorter-down {
        color: white;
      }
    }
  }

  .ant-table-row-expand-icon {
    color: var(--color-primary) !important;
  }

  table>tbody>tr td {
    color: white;
    border-bottom: none !important;
  }

  /* Apply background and border to ant-table-row-level-0 rows except the first row */
  table>tbody>.ant-table-row-level-0:not(:first-child) td {
    background-color: var(--color-80-black) !important;
    border-top: 0.5px solid var(--color-50-black) !important;
  }

  /* Ensure child rows (.ant-table-row-level-1) have consistent background */
  table>tbody>.ant-table-row-level-0~.ant-table-row-level-1 td {
    background-color: var(--color-80-black) !important;
  }

  /* Remove border for the very first row in the table */
  table>tbody>tr:first-child td {
    background-color: var(--color-80-black) !important;
    border-top: none !important;
  }

  table>tbody>.ant-table-row-level-1 td:first-child {
    padding-left: 50px !important;

    span {
      padding: 0 !important;
    }

    button {
      display: none !important;
    }
  }

  table>tbody>.ant-table-row-level-0 td:first-child {
    align-items: center;

    /* Center content vertically */
    button {
      align-items: center;
      margin: 15px 15px 15px 0;
    }

  }

  .ant-empty-description {
    color: white !important;
  }

  .ant-table-thead>tr>th.ant-table-column-has-sorters.ant-table-column-sort {
    background-color: #000000;
  }

  .ant-table-expanded-row {
    color: white;

    .ant-table-cell {
      background-color: inherit !important;
      /* Inherit background color from parent row */
      padding-top: 10px !important;
    }
  }

  .ant-table-expanded-row>td {
    border-bottom: 1px solid white;
  }
}



.ant-table-filter-dropdown {
  background: var(--color-70-black);

  .ant-table-filter-dropdown-search {
    border: none;
  }

  .ant-table-filter-dropdown-search-input {
    background: transparent;
    border-radius: 0;
    border: 1px solid var(--color-60-black);
    box-shadow: none;
    padding: 0 11px;

    &:hover {
      background: var(--color-hover-black) !important;
      border-color: var(--color-60-black);
    }

    .anticon {
      color: var(--color-white);
    }

    .ant-input {
      border: none;

      &:hover,
      &:focus {
        background: transparent !important;
        border: none !important;
      }
    }
  }

  .ant-dropdown-menu {
    background: var(--color-70-black);

    .ant-dropdown-menu-item {
      &:hover {
        background: var(--color-80-black);
      }
    }
  }

  .ant-table-filter-dropdown-btns {
    border: none;
  }
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: var(--color-50-black) !important;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  background: transparent;
  color: var(--color-white);
}