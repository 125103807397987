.browse-feature-drawer {
    .browse_drawer-footer {
        display: flex !important;
        justify-content: space-between !important;
        margin-top: 20px !important;
        position: sticky !important;
        bottom: 0 !important;
    }

    .ant-drawer {
        background-color: transparent !important;
    }

    .ant-drawer-content-wrapper {
        background-color: transparent !important;
    }

    .drawer-content {
        display: flex !important;
        flex-direction: row !important;
        /* Ensure side-by-side layout */
        gap: 20px !important;
    }

    .tree-container,
    .selected-items {
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start !important;
        width: 50% !important;
        /* Ensure equal width for side-by-side */
        overflow-y: auto !important;
        background-color: var(--color-70-black) !important;
        /* Add background color */
        min-height: 300px !important;
    }

    .selected-items {
        padding: 0 20px !important;
        /* Ensures padding on left and right */

        h3 {
            margin-top: 20px !important;
            /* Adds space above the heading */
            margin-bottom: 16px !important;
            /* Adds space below the heading */
            color: var(--color-white) !important;
            /* Sets text color to white */
        }

        .ant-list {
            overflow-y: auto !important;
            /* Allows vertical scrolling */
            border: none !important;
            /* Removes border */
            background-color: var(--color-80-black) !important;
        }

        .ant-list-item {
            color: var(--color-white) !important;
            /* Sets text color to white */
            border: none !important;
            /* Removes border */
            margin-left: 20px !important;
            /* Adds left margin */
        }

        .ant-list-empty-text {
            color: var(--color-white) !important;
        }
    }

    .tree-node {
        border: none !important;

        .ant-checkbox-wrapper {
            padding: 0 10px 10px 10px !important;
            /* Top, Right, Bottom, Left */
            border: none !important;
            color: var(--color-white) !important;
            margin-left: 20px !important;
        }
    }

    .tree-container .ant-collapse {
        border: none !important;
        background-color: var(--color-70-black) !important;
        /* Remove border */
    }

    .tree-container .ant-collapse-header {
        color: var(--color-white) !important;
        background-color: var(--color-70-black) !important;
        border: none !important;
        align-items: center !important;
    }

    .tree-container .ant-collapse-content-box {
        color: var(--color-white) !important;
        background-color: var(--color-70-black) !important;
        border: none !important;
        /* Remove border */
        padding: 0 10px 10px 10px !important;
        /* Top, Right, Bottom, Left */
    }

    .tree-container .ant-collapse-item {
        border: none !important;
        /* Remove border */
    }

    .tree-container .ant-collapse-content {
        border: none !important;
        /* Remove border */
    }

    .dark-theme-input {
        background-color: var(--color-charcoal-surface-2) !important;
        color: var(--color-white) !important;
        border: 1px solid var(--color-charcoal-surface-3) !important;
    }

    .dark-theme-input .ant-input {
        background-color: var(--color-charcoal-surface-2) !important;
        color: var(--color-white) !important;
    }

    .dark-theme-input .ant-input-prefix {
        color: var(--color-white) !important;
    }

    .feature-table {
        width: 100%;
        border-collapse: collapse;
        font-size: 16px;
        text-align: left;
    }

    .feature-table th,
    .feature-table td {
        padding: 12px 15px;
        border: 1px solid var(--color-charcoal-surface-3);
    }

    .feature-table th {
        background-color: var(--color-charcoal-surface-2);
        font-weight: bold;
        color: var(--color-white);
    }

    .feature-table .selected {
        background-color: var(--color-charcoal-surface-4);
    }
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;