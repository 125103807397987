/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-corner { 
  background: transparent; 
  width: 0;
  height: 0;
  border:none;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-70-black); 
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--color-60-black); 
  transition: background 0.2s ease-in;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-50-black); 
}
#app {
  h1, h2, h3, h4, h5, h6 {
    color:var(--color-white);
  }
}

.ant-divider {
  border-color: var(--color-60-black);
}

.ant-message {
  color: var(--color-80-black);
}

.ant-empty-description {
  color: var(--color-gale-gray) !important;
}
