.chatToolContainer{
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border-radius: 8px !important;
        padding: 5px 16px !important;
        background-color: var(--color-charcoal-surface-1) !important;
        color: white !important;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
        border-radius: 8px !important;
        padding: 5px 16px !important;
        background-color: var(--color-charcoal-surface-1) !important;
        color: white !important;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        color: white !important;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item {
        color: white !important;
        font-size: 1.143rem !important;
    }

    .ant-input:active, .ant-input:hover{
        border: none !important;
        background: transparent !important;
    }

    .ant-select{
        max-width: 250px;
        .ant-select .ant-select-arrow {
            left: 220px;
        }
        .ant-select .ant-select-arrow {
            @media screen and (min-width: 768px) {
                left: 40px;
            }
        
            @media screen and (min-width: 1200px) {
                left: 220px;
            }
        }
        .ant-select .ant-select-arrow:hover {
            border-radius: 8px !important;
            padding: 5px 16px !important;
        }
    }

    .ant-cascader{
        position: relative;
        top: 10px;
        @media screen and (max-width: 768px) {
            left: 2px !important;
            top: 2px !important;
        }
    
        @media screen and (max-width: 1200px) {
            left: 330px;
        }
        left: 330px;
    }

    .ant-dropdown-trigger {
        margin-left: 324px;
        margin-top: 4px;
    
        @media (max-width: 768px) {
          margin-left: 4px;  
        }
    }  
    
    .ant-dropdown-menu {
        background-color:var(--color-charcoal-surface-1)!important;
        color: var(--color-white) !important;
    }
    
    .ant-dropdown-menu-item {
        color: var(--color-white) !important;
    }
    
    .ant-dropdown-menu-item:hover {
    background-color: #333 !important;
    }
    
    .ant-btn {
        color: var(--color-white) !important;
        background-color: transparent !important;
        border: 1px solid var(--color-50-black) !important;
        border-radius: 8px !important;
        padding: 8px 8px !important;
        justify-content: space-between !important;
        font-weight: 400 !important;
        font-size: 14px;
        text-transform: none;
    }
    
    
    .ant-dropdown-menu-submenu-popup {
        inset: 44px auto auto 480px !important;
        border-radius: 8px !important;
    
        @media (max-width: 768px) {
            inset: 104px auto auto 160px !important; 
        }
    
        ul li{
            margin-bottom: 0px !important;
        }
    }
    
    .ant-dropdown-menu-submenu-arrow-icon{
        color: inherit !important;
    }
}

.modelLLmDropdown {
    display: flex;
    background-color: black;

    .ant-cascader-menus {
        background-color: rgba(50, 50, 50, 0.9);
        padding: 4px;
        min-width: fit-content;
    }

    .ant-cascader-menu:first-child {
        max-height: 200px;
        height: fit-content;
        width: fit-content;
        min-width: 150px;
        overflow-y: auto;
        background-color: transparent;
    }

    @media screen and (max-width: 768px) {
        .ant-cascader-menu:first-child {
            min-width: auto;
        }
    }

    .ant-cascader-menu:nth-child(2) {
        height: 160px;
        width: fit-content;
        max-height: 200px;
        min-width: 200px;
        overflow-y: auto;
        background-color: var(--color-charcoal-surface-1);
    }

    @media screen and (max-width: 768px) {
        .ant-cascader-menu:nth-child(2) {
            min-width: auto;
        }
    }

    .ant-cascader-menu-item {
        color: gray;
    }

    .ant-cascader-menu-item-active {
        background-color: var(--color-40-black);
        color: white;
        font-weight: 400;
        font-size: 1.143rem;
    }

    .ant-cascader-menu-item-active .ant-cascader-menu-item-expand-icon {
        color: white;
    }

    .ant-cascader-menu-item:hover {
        background-color: var(--color-60-black);
        color: white;
    }

    .ant-cascader-menu-item:hover .ant-cascader-menu-item-expand-icon {
        color: white;
    }

    .ant-cascader-menu-item-expand-icon {
        color: gray;
    }

    .ant-cascader-menu-item-expand-icon:hover {
        color: white;
    }

    span.ant-select-selection-item {
        font-size: 1.143rem;
    }

    .ant-cascader-menu,
    .ant-cascader-menu-item,
    .ant-cascader-menu-item-active,
    .ant-cascader-menu-item:hover,
    .ant-cascader-picker,
    .ant-cascader-picker-label {
        font-size: 1.143rem;
        font-family: inherit;
        color: white;
    }

    .ant-select-customize-input .ant-select-selector .ant-select-selection-item {
        font-size: 1.143rem;
        font-family: inherit;
        color: white;
    }

    .ant-select-selector {
        width: auto;
        min-width: 150px;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}



    

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;