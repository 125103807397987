.c4hh0jv .ant-checkbox-disabled + span{color:var(--color-white);}
.m1cj9cb6{cursor:pointer;aspect-ratio:1;border:1px solid #666;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;background:var(--color-full-black) !important;width:160px;height:160px;-webkit-transition:all 0.2s;transition:all 0.2s;padding:24px 16px;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;outline:none;text-align:left;color:var(--color-gale-gray);}.m1cj9cb6 .icon{color:white;opacity:0.8;-webkit-transition:all 0.2s ease-in;transition:all 0.2s ease-in;}.m1cj9cb6.align-bottom{-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;}@media screen and (min-width:768px){.m1cj9cb6{width:193px;height:193px;}}.m1cj9cb6:hover{border-color:var(--color-white);background:var(--color-charcoal-surface-1) !important;}.m1cj9cb6:hover .arrow{-webkit-transform:translateX(4px);-ms-transform:translateX(4px);transform:translateX(4px);}.m1cj9cb6:hover .icon{opacity:1;}.m1cj9cb6:focus{outline:none;border-color:var(--color-primary);background:var(--color-charcoal-surface-2) !important;}.m1cj9cb6:focus .arrow{color:var(--color-primary);-webkit-transform:translateX(4px);-ms-transform:translateX(4px);transform:translateX(4px);}.m1cj9cb6:focus .plus-btn{color:var(--color-primary);}
.mvns3zh{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.uq86ej9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;gap:1rem;}
.u16bfq8i{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;}
.u12n200v{max-height:140px;overflow-y:auto;}
.urxvcqk{background:var(--color-charcoal-surface-1);padding:9px 10px;border-radius:5px;}
.u15aj65i{background:var(--color-charcoal-surface-1);padding:8px;border-radius:4px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.c1kkgmcj{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;}
.d1o664ld{opacity:0.7;pointer-events:none;cursor:not-allowed;}
.l1buvh9c{margin-bottom:1rem;}.l1buvh9c .ant-list-header{border-bottom:none !important;padding-bottom:0 !important;}
.ldjjghg{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;color:white;border:none;padding:24px;}
.lnjlpy4{margin-top:1rem;padding:24px 16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:0px;font-size:14px;background:var(--color-charcoal-surface-1);color:var(--color-white);gap:8px;}@media screen and (min-width:768px){.lnjlpy4{padding:24px;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}}
.l1ds8ohb .list-item-label{color:var(--color-40-black);margin-bottom:0;}.l1ds8ohb .list-item-value{width:100%;overflow:hidden;text-overflow:ellipsis;margin-bottom:0;}
.d3ej12p{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:8px;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;