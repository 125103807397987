.custom-input-search.ant-input-group-wrapper {
    width: 100%;
    max-width: 254px;
    margin-left: 16px;

    @media (max-width: 1024px) {
        max-width: 80%;
    }

    @media (max-width: 768px) {
        max-width: 90%;
        margin-left: 8px;
        margin-top: 16px;
    }

    @media (max-width: 480px) {
        max-width: 100%;
        margin-left: 0px;
        margin-top: 16px;
    }

    .ant-input-group-wrapper-outlined.ant-input-group-wrapper-disabled .ant-input-group-addon {
        border-color: none !important;
    }

    .ant-input-group {
        color: var(--color-default-text-color);
        &:active,
        &:focus,
        &:focus-visible,
        &:focus-within {
            .ant-input-affix-wrapper,
            .ant-input-group-addon {
                border: 0 none;
            }
        }

        .ant-input-affix-wrapper {
            border: 0 none;
            background-color: transparent;
            padding: 12px;
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;


            @media (max-width: 1024px) {
                padding: 10px;
            }

            @media (max-width: 768px) {
                padding: 8px;
            }

            @media (max-width: 480px) {
                padding: 6px;
            }

            .ant-input {
                color: var(--color-default-text-color);
                background-color: transparent !important;
                border: none !important;
            }

            .ant-input-suffix {
                color: var(--color-default-text-color);
            }
        }

        .ant-input-group-addon {
            background-color: transparent;
            border: 0 none;
            color: var(--color-default-text-color);

            .ant-btn.ant-btn-primary.ant-input-search-button {
                background-color: transparent;
                border: none;
                color: var(--color-default-text-color);

                .anticon.anticon-search {
                    color: var(--color-default-text-color);
                }
            }
        }
    }
}
.ant-input-number-outlined.ant-input-number-disabled {
    border: 1px solid var(--color-60-black) !important;
}

.custom-input.ant-input-affix-wrapper {
    border: solid 1px #8d8d8d;
    background-color: transparent;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0;

    @media (max-width: 1024px) {
        padding: 10px;
    }

    @media (max-width: 768px) {
        padding: 8px;
    }

    @media (max-width: 480px) {
        padding: 6px;
    }

    .ant-input {
        color: var(--color-default-text-color);
        background-color: transparent !important;
        border: none !important;
        :hover {
            border: none !important;
            background-color: transparent !important;
        }
    }

    .ant-input-suffix {
        color: var(--color-default-text-color);
    }
}

.ant-input-affix-wrapper.custom-input.ant-input-affix-wrapper-status-error {
    background-color: transparent !important;
}

.ant-input-number.ant-input-number-in-form-item.custom-input {
    background-color: transparent;
    padding: 12px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    color: var(--color-default-text-color);

    @media (max-width: 1024px) {
        padding: 10px;
    }

    @media (max-width: 768px) {
        padding: 8px;
    }

    @media (max-width: 480px) {
        padding: 6px;
    }

    .ant-input {
        color: var(--color-default-text-color);
        background-color: transparent;
        border: none;
    }
    .ant-input-number-input {
        padding: 0;
    }

    .ant-input-suffix {
        color: var(--color-default-text-color);
    }
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;