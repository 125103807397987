
.f1oxqlsp{width:100%;color:var(--color-gale-gray) !important;}.f1oxqlsp .ant-form-item-control-input-content{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:nowrap;-ms-flex-wrap:nowrap;flex-wrap:nowrap;gap:8px;}
.u1i6i1zp .ant-upload-drag{background-color:#1e1e1e;border:2px dashed #3a3a3a;border-radius:12px;color:#b0b0b0;-webkit-transition:all 0.3s ease;transition:all 0.3s ease;}.u1i6i1zp .ant-upload-drag:hover{border-color:#666;}.u1i6i1zp .ant-upload-drag .ant-upload-text{font-size:14px !important;color:#dcdcdc;}
.ub1pbsl{width:100%;border-radius:12px;padding:0;}.ub1pbsl .ant-card-body{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:10px 24px !important;}
.ux18yqb{color:#d0d0d0;font-size:12px;padding:15px 0px;}.ux18yqb p{margin-bottom:0 !important;}
.g1ry5er8{color:#60aaff;-webkit-text-decoration:none;text-decoration:none;font-weight:500;-webkit-transition: color 0.3s ease, -webkit-transform 0.3s ease;-webkit-transition: color 0.3s ease, transform 0.3s ease;transition: color 0.3s ease, transform 0.3s ease;}.g1ry5er8:hover{-webkit-text-decoration:underline;text-decoration:underline;color:#1a8fff;-webkit-transform:translateY(-2px);-ms-transform:translateY(-2px);transform:translateY(-2px);}
.f1iiwit5{font-size:14px;color:black;font-weight:500;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;text-align:left;word-break:break-all;}
.d144aogp{color:red;font-size:18px;cursor:pointer;}
.u1d6jfqk{font-size:32px !important;color:#dcdcdc;}
.a13298zb{text-align:center;color:#888;}
.t1liercr::-webkit-input-placeholder{color:gray !important;opacity:1;}.t1liercr::-moz-placeholder{color:gray !important;opacity:1;}.t1liercr:-ms-input-placeholder{color:gray !important;opacity:1;}.t1liercr::placeholder{color:gray !important;opacity:1;}
.s8ih8am{font-size:14px;font-weight:600;color:var(--color-gale-gray);margin-bottom:6px;display:block;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;