.ant-breadcrumb {
  color:var(--color-white) !important;
  .ant-breadcrumb-link,
  .ant-breadcrumb-separator,
  a {
    background: transparent;
    color:var(--color-white) !important;
  }
  a:hover {
    color:var(--color-primary) !important;
  }
}