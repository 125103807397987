.mediaplanCreateLayout {
  .steps {
    margin-bottom: 20px;
    color: white !important;
  }
  
  .step-title {
    color: #fff !important;
  }
  
  .step-title-active {
    color: var(--color-primary) !important;
  }
  
  .step-icon {
    color: #fff !important;
  }
  
  .step-icon-active {
    color: var(--color-primary) !important;
  }
  
  .steps-item-inactive .steps-item-tail::after {
    background-color: #555 !important;
  }
  
  .ant-steps-item-title::after  {
    background-color: #f0f0f0 !important;
  }
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;