.r1fifqe7{max-width:100%;margin:0 auto;border-radius:8px;}
.rb3xjup{color:var(--color-white);margin-bottom:24px;font-size:24px;font-weight:600;}
.so079qb{margin-bottom:24px;padding:20px;background-color:var(--color-90-black);border-radius:8px;}
.sdwa3kk{color:var(--color-white);margin-bottom:16px;font-size:18px;font-weight:600;}
.iyzdrto{display:grid;grid-template-columns:auto 1fr;gap:8px 16px;-webkit-align-items:baseline;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;}.iyzdrto .ant-typography{color:var(--color-white);font-size:14px;}.iyzdrto .ant-typography strong{color:var(--color-30-gray);}
.s1iqb0mv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:10px;}
.p1ik2ze9{height:28px !important;width:35px !important;font-size:12px !important;border-radius:4px !important;}
.lnmcunk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;min-height:200px;}
.e1fhvv31{text-align:center;padding:32px;color:var(--color-60-gray);}
.i1at7f34{color:var(--color-60-gray);font-size:14px;cursor:help;margin-left:8px;-webkit-transition:color 0.2s ease;transition:color 0.2s ease;}.i1at7f34:hover{color:var(--color-link);}
.e53cmzk{color:var(--feedback-dislike);}.e53cmzk:hover .ant-table-cell.ant-table-cell,.e53cmzk:hover td{color:var(--feedback-dislike) !important;}
.t2lj8zj .ant-tooltip-inner{width:-webkit-max-content;width:-moz-max-content;width:max-content;max-width:1000px;}
.n15lv5cv.ant-input-number{margin-left:10px;padding:0 10px !important;width:60px !important;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;