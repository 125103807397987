.cnr213b{box-shadow:rgba(149,157,165,0.2) 0px 8px 24px;border-radius:8px;text-align:center;height:200px;width:200px;cursor:pointer;-webkit-transition:-webkit-transform 0.3s;-webkit-transition:transform 0.3s;transition:transform 0.3s;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;word-wrap:break-word;}.cnr213b.ant-card-bordered{border-radius:10px;}.cnr213b.ant-card-bordered .ant-card-body{padding:24px 14px;max-height:calc(100vh - 238px);overflow-y:auto;}.cnr213b.card-primary{background-color:var(--color-primary) !important;}.cnr213b.card-gray{background-color:var(--color-full-black) !important;}.cnr213b.card-hover:hover{-webkit-transform:scale(1.05);-ms-transform:scale(1.05);transform:scale(1.05);}.cnr213b.card-disabled{background-color:var(--color-full-black) !important;color:#aaa !important;cursor:not-allowed;}.cnr213b.card-disabled .ant-card-meta-title,.cnr213b.card-disabled .ant-card-meta-description{color:#aaa !important;}.cnr213b .ant-card-meta-title{width:180px !important;font-size:18px;font-weight:bold;color:white !important;white-space:pre-wrap !important;}.cnr213b .ant-card-meta-description{font-size:14px;color:white !important;word-wrap:break-word;}.cnr213b .ant-radio-inner{background-color:transparent;}
.f1v4bv1i{-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;}.f1v4bv1i .ant-form-item-label > label{width:100% !important;}
.c1buklmv{color:gray;margin-bottom:8px;}
.dp17wdl{position:absolute;height:auto;top:40px;right:20px;}
.dy6sr6r{position:relative;}
.b1livt9p{margin-bottom:16px !important;}
.a1fpnfc{margin-top:-10px;margin-bottom:24px;}
.c1x6m21i{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:20px;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;}
.c91otz8{text-align:center;font-weight:bold;margin-top:10px;}
.r16qgau9{margin-bottom:16px;}
.eka7v0y{color:var(--color-primary);margin-left:8px;}
.c6bfr60{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.r1dlzn3s{position:absolute;top:10px;left:10px;}
.f1kuvn57{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:100%;}
.fcuvrgj{margin-bottom:10px;}
.tnj1ozt{margin-bottom:10px;}
.t1d8gj7u{border:1px solid #fff;padding:10px;border-radius:5px;background-color:#000;max-width:70%;}
.sjs8k1p{width:300px;max-width:300px;}
.ssxkqop{color:var(--color-gale-gray);font-weight:400;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;