// .ant-collapse-arrow {
//   transform: rotate(180deg);
// }

// .ant-collapse-arrow svg {
//   transform: rotate(-90deg);
// }

.ant-collapse .ant-collapse-item-disabled>.ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled>.ant-collapse-header>.arrow {
  cursor: default;
}

.regularCollapse {
  &.ant-collapse {
    border-radius: 4px;
    margin-bottom: 1rem;
    background: var(--color-charcoal-surface-2);
    border: 0;
    transition: all 0.2s ease-in;

    &:hover {
      background: var(--color-70-black);
    }
  }

  &.ant-collapse .ant-collapse-item .ant-collapse-header {
    padding: 9px 14px;
    border: 0;
    flex-direction: row-reverse;
  }

  &.ant-collapse>.ant-collapse-item {
    border: 0;
  }

  &.ant-collapse .ant-collapse-content .ant-collapse-content-box {
    padding: 16px 24px;
    background: var(--color-charcoal-surface-2) !important;
    border-radius: 0 0 4px 4px;
  }

  &.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    color: var(--color-gale-gray);
  }

  .ant-collapse-content {
    border: none;
    background-color: transparent;
  }
}

.drawerCollapse {
  &.ant-collapse {
    border-radius: 4px;
    margin-bottom: 1rem;
    background: var(--color-charcoal-surface-1);
    border: none;
  }

  &.ant-collapse .ant-collapse-item .ant-collapse-header {
    padding: 9px 14px;
    color: var(--color-white) !important;
    border: none;
    flex-direction: row-reverse !important;
  }

  &.ant-collapse>.ant-collapse-item {
    border: none;
  }

  &.ant-collapse .ant-collapse-content {
    border-top: none !important;
    background-color: transparent;
  }

  &.ant-collapse .ant-collapse-content .ant-collapse-content-box {
    padding: 16px 24px;
    background: var(--color-charcoal-surface-1) !important;
    color: var(--color-white) !important;
    border-radius: 0 0 4px 4px;
  }
}

.analysisCollapse {
  &.ant-collapse {
    border-radius: 10px;
    margin-bottom: 0.5rem;
    background-color: transparent;
    border: 0;

    .ant-collapse-item {
      border: none;
    }

    .ant-collapse-item .ant-collapse-header {
      width: fit-content;
      border-radius: 4px !important;
      border: 0;
      font-size: 0.85rem;
      background-color: var(--color-charcoal-surface-3);
      padding: 4px 8px;
      color: white;

      .ant-collapse-header-text {
        padding-right: 5px;
      }

      .ant-collapse-expand-icon {
        margin-right: 4px;
      }

    }

    .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
      width: 100%;
      border-radius: 4px 4px 0px 0px;
    }

    .analysis-item .ant-collapse-header {
      flex-direction: row-reverse;
      border-radius: 4px !important;
      background: black !important;

      .ant-collapse-extra {
        padding-right: 0.5rem;
      }
    }

    .ant-collapse-item-active.analysis-item .ant-collapse-header {
      background: transparent !important;
      border-radius: 4px !important;
    }

    .ant-collapse-content {
      background-color: var(--color-charcoal-surface-3) !important;
      border: 0;
      border-radius: 0px 0px 4px 4px;
      width: 100%;

      .ant-collapse-content-box {
        color: var(--color-white) !important;
        padding: 10px !important;
        font-size: 1rem;
      }
    }
  }
}

.toolCollapse {
  &.ant-collapse {
    border-radius: 10px;
    margin-bottom: 0.5rem;
    background-color: transparent;
    border: 0;

    .ant-collapse-item.ant-collapse-item.ant-collapse-item {
      border-radius: 10px;

      .ant-collapse-header {
        align-items: center;
        padding: 0;
        border-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border: 0;
        font-size: 0.85rem;
        background-color: var(--color-charcoal-surface-1);
        padding: 6px;
        color: var(--color-white);
        font-weight: 700;
        width: 100%;

        .ant-collapse-header-text {
          padding-right: 5px;
        }

        .ant-collapse-extra {
          padding-left: 0.5rem;
        }
      }

      .ant-collapse-content {
        width: 100%;
        border: 0;
        border-radius: 5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: transparent;

        .ant-collapse-content-box {
          padding: 16px 24px;
          border-radius: 10px;
        }
      }
    }
  }

  &.ant-collapse>.ant-collapse-item {
    border: 0;
  }

  &.ant-collapse .ant-collapse-content .ant-collapse-content-box {}
}