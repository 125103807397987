.c1wpzdg7{overflow-y:auto;overflow-x:hidden;}
.f1cf937y{max-width:1400px;}
.a1qh089{color:white;margin-bottom:1rem;}
.clvn4t{margin-top:1rem;}.clvn4t .react-colorful{height:150px;width:150px;}
.c158lves{background:#db5a4b !important;color:white !important;font-weight:600 !important;border:0 !important;}
.u4fdm7l .ant-upload.ant-upload-select-picture-card{background-color:transparent;border:0 none;height:auto;}
.l189nhdw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:10px;}
.u1winsz9{margin-left:2px;margin-right:8px;color:#c5cbd2;}
.ae4jb27{pointer-events:none;max-height:1px;margin-bottom:0 !important;overflow:hidden;opacity:0;}
.b1hyj15v{margin:24px 0;gap:8px;}.b1hyj15v > button{width:48%;}@media screen and (min-width:768px){.b1hyj15v > button{width:auto;}}
.c1adfa19{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;}.c1adfa19 .anticon{margin-top:-1.2rem;color:red;}
.cerwdz8 .ant-cascader-dropdown .ant-cascader-menu-item{background-color:var(--color-60-black) !important;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;