.fqwtqxj{width:100%;}
.f15r6zdu{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:24px;}
.f1uxayuk{font-size:18px;color:var(--color-white);margin:0;}
.ew61nx1{text-align:center;padding:48px 24px;background-color:var(--color-90-black);border-radius:8px;color:var(--color-60-gray);}
.c1qrd4yz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:16px;padding:0 12px 8px;border-bottom:1px solid var(--color-80-black);margin-bottom:16px;}
.h11p33du{color:var(--color-60-gray);font-size:12px;text-transform:uppercase;font-weight:500;}.h11p33du:nth-child(1){width:200px;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;}.h11p33du:nth-child(2){-webkit-flex:1;-ms-flex:1;flex:1;}
.h2tqrly{width:32px;}
.s1rvutlo{overflow-y:auto;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:12px;padding-right:4px;margin-bottom:8px;}.s1rvutlo::-webkit-scrollbar{width:6px;}.s1rvutlo::-webkit-scrollbar-track{background:var(--color-90-black);border-radius:3px;}.s1rvutlo::-webkit-scrollbar-thumb{background:var(--color-70-black);border-radius:3px;}
.c1j4bt7{background-color:var(--color-90-black);border-radius:8px;padding:16px;min-height:200px;}
.i1e6ce1i{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;gap:16px;border-radius:8px;padding:12px;position:relative;-webkit-transition:background-color 0.2s ease;transition:background-color 0.2s ease;margin-bottom:10px;}.i1e6ce1i .ant-form-item{margin-bottom:0;position:relative;}.i1e6ce1i .ant-form-item:nth-child(1){width:200px;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;}.i1e6ce1i .ant-form-item:nth-child(2){-webkit-flex:1;-ms-flex:1;flex:1;min-width:0;}.i1e6ce1i .ant-form-item-explain{position:absolute;top:calc(100% + 4px);left:0;font-size:12px;white-space:nowrap;z-index:1;}.i1e6ce1i .ant-select,.i1e6ce1i .ant-input{background-color:var(--color-80-black);color:var(--color-white);-webkit-transition:border-color 0.2s ease;transition:border-color 0.2s ease;}.i1e6ce1i .ant-select:hover,.i1e6ce1i .ant-input:hover{border-color:var(--color-link);}.i1e6ce1i .ant-select-focused .ant-select-selector,.i1e6ce1i .ant-input:focus{border-color:var(--color-link) !important;box-shadow:0 0 0 2px rgba(220,90,75,0.2) !important;}
.c1giti72{width:100% !important;}
.d1qki0g3{color:var(--color-tag-red-txt);cursor:pointer;font-size:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:32px;-webkit-transition:color 0.2s ease;transition:color 0.2s ease;margin-top:10px;}.d1qki0g3:hover{color:var(--color-tag-red-border);}
.d1fgyihv{opacity:1;}
.a4a31dh{background-color:var(--color-link);border:none;-webkit-transition:all 0.2s ease;transition:all 0.2s ease;}.a4a31dh:hover{background-color:var(--color-primary-tint-90);-webkit-transform:translateY(-1px);-ms-transform:translateY(-1px);transform:translateY(-1px);}.a4a31dh:active{-webkit-transform:translateY(0);-ms-transform:translateY(0);transform:translateY(0);}
.eqc8i5o{margin-top:16px;color:var(--color-tag-red-txt);padding:8px 12px;background-color:var(--color-80-black);border-radius:4px;border:1px solid var(--color-tag-red-txt);}
.t1m01j3m{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:8px;}
.i1mdc4ta{color:var(--color-60-gray);font-size:14px;cursor:help;-webkit-transition:color 0.2s ease;transition:color 0.2s ease;}.i1mdc4ta:hover{color:var(--color-link);}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;