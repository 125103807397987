.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1003 !important;
}

.ant-modal {
  .ant-modal-close-x {
    color: var(--color-white);
  }

  .ant-modal-confirm-title {
    color: var(--color-white);
  }

  .ant-modal-content {
    background: var(--color-charcoal-surface-2);
    border-radius: 10px;
    color: var(--color-white);
    width: 100%;
    height: 100%;
  }

  .ant-modal-header {
    background-color: transparent;
    color: var(--color-white);

    .ant-modal-title {
      color: var(--color-white);
    }
  }

  .ant-modal-body {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .ant-btn-primary {
    width: 100%;
    background-color: transparent;
  }

  .ant-modal-confirm-btns {
    display: flex;

    >button {
      width: 50% !important;
    }
  }
}

.ant-modal-confirm-content {
  color: var(--color-white) !important;
}

.ant-modal-confirm-warning .ant-modal-confirm-body>.anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body>.anticon {
  color: var(--color-primary);
}

.disclaimerModal {
  top: 35vh !important;

  .ant-modal-close-x {
    color: var(--color-full-black);
  }

  .ant-modal-content {
    background: var(--color-white);
    color: var(--color-full-black);
  }
}