.media-plan-overview-container{
  background-color: var(--color-charcoal-surface-2);
  border-radius: 8px;

  .charts-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    padding: 6px 6px 0px;
    .media-plan-chart-container{
      flex: 1;
      min-width: 300px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 12px;
      padding-bottom: 6px;
      background-color: var(--color-full-black);
      border-radius: 8px;

      h2{
        margin-top: 6px;
        margin-left: 20px ;
      }
    }
  }

  .second-row .media-plan-chart-container:first-child {
    margin-right: 1rem !important;
  }

  .charts-row:last-child {
    padding-bottom: 6px !important;
  }
  
  .header-container {
    border: 1px dashed #8d8d8d;
    border-radius: 4px;
    text-align: center;
    margin: 0 0 1rem 0;
  
    .ant-col {
      border-right: 1px dashed #8d8d8d;
      padding: 8px;
    }
    .planning {
      margin-right: 0;
    }
  }
  .tableContainer {
    .ant-table-tbody > tr > td {
      padding: 6px 0 !important;
    }
  }
  
  .tableAudienceContainer {
    padding: 0 30px;
    border: 1px solid var(--color-70-black);
    margin-bottom: 20px;
  }

  .forecasttableContainer {
    padding: 10px 10px;
    margin-bottom: 5px;
    background-color: var(--color-70-black);
  }
  
  .tableContainer {
    padding: 10px 10px 0px;
    margin-bottom: 5px;
    background-color: var(--color-70-black);
  
    .ant-table{
      background-color: var(--color-70-black);
    }
    .ant-tabs-nav .ant-tabs-tab-btn {
      text-transform: none;
    }
  }
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;