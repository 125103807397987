.k5fcfoc{margin-top:20px;}.k5fcfoc .ant-typography{color:white;}.k5fcfoc .ant-list-item{color:white;}
.dhm63x9 table{border-color:white;}.dhm63x9 table > thead > tr > th{background-color:#171717;color:white;}.dhm63x9 table > tbody > tr > td{background-color:#222222 !important;color:white;border-bottom:none;}.dhm63x9 .ant-empty-description{color:white;}.dhm63x9 .ant-pagination li{border:0px;background-color:#222222;color:white;}.dhm63x9 .ant-pagination li button{background-color:#222222;color:white;border:none;}.dhm63x9 .ant-pagination li button:hover{background-color:#222222;color:white;}.dhm63x9 .ant-pagination li a{border:0px;background-color:#222222;color:white;}.dhm63x9 .ant-pagination-item-active{border:1px solid white !important;}
.azf8mep{width:30% !important;}
.a1q1ugj6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-webkit-justify-content:end;-ms-flex-pack:end;justify-content:end;}
.c1caskpf{color:white;margin-bottom:8px;padding:5px;border-radius:5px;margin:5px;background-color:#3c3b3b;}
.plu5vys{text-align:"center";margin-top:16;}
.cxbfuad{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:16px;}
.o1ua868k.ant-card{min-width:32.5%;max-width:32.5%;-webkit-flex:1 1 calc(33.333% - 16px);-ms-flex:1 1 calc(33.333% - 16px);flex:1 1 calc(33.333% - 16px);margin-bottom:16px;position:relative;background:var(--color-charcoal-surface-2);}
.s8xmp5l{position:absolute;top:8px;right:8px;font-size:18px;cursor:pointer;color:white;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.o95xq8j{font-size:12px;color:white;padding-top:12px;}
.o1f3royu .ant-list-item.ant-list-item-no-flex{border:None;}
.n6llb9m{color:white;margin-bottom:8px;padding:5px;border-radius:5px;margin:5px;margin-left:0;margin-right:10px;background-color:#3c3b3b;}
.twyp1jr{font-size:12px;color:white;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;max-height:200px;max-width:268px;overflow-y:auto;}
.ti0oh29{color:black;background-color:white;border-radius:3px;margin:5px;border:solid 0.5px #c6c6c685;padding:3px;padding-top:4px;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.tnkiwz9 .ant-popover-inner-content{padding:5px;box-shadow:0px 0px 1px 0px #ffffff8a;}.tnkiwz9 .ant-popover-arrow > .ant-popover-arrow-content{box-shadow:0px 0px 1px 0px #ffffff8a;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;