.cybysq8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;}
.f3sctfh{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;gap:16px;width:100%;margin-top:28px;}@media (max-width:768px){.f3sctfh{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}}
.f1u0mpbn{background-color:var(--color-90-black);padding:24px;border-radius:8px;border:1px solid var(--color-80-black);-webkit-transition:all 0.3s ease;transition:all 0.3s ease;cursor:pointer;position:relative;width:280px;height:200px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.f1u0mpbn:hover:not(.disabledCard){border-color:var(--color-link);}@media (max-width:768px){.f1u0mpbn{width:100%;max-width:280px;}}
.sxfdor8{border-color:var(--color-link) !important;background-color:rgba(220,90,75,0.1) !important;}
.d1mw05pz{opacity:0.6;cursor:not-allowed;}.d1mw05pz:hover{-webkit-transform:none;-ms-transform:none;transform:none;border-color:var(--color-80-black);}
.f1e5gzpk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;text-align:center;width:100%;}
.f8ckagb{font-size:32px;color:var(--color-link);margin-bottom:16px;}
.f1a5hzya{font-size:18px;font-weight:500;color:var(--color-white);margin-bottom:8px;}
.f121teq9{font-size:14px;color:var(--color-60-gray);line-height:1.5;}
.sa4jdbj{position:absolute;top:8px;right:8px;background-color:var(--color-80-black);color:var(--color-white);font-size:12px;padding:2px 8px;border-radius:4px;}
.i6eecos{width:100%;margin-top:24px;background-color:var(--color-90-black);padding:24px;border-radius:8px;}.i6eecos .ant-form-item{margin-bottom:16px;}.i6eecos .ant-form-item-label > label{color:var(--color-white);}.i6eecos .ant-input{background-color:var(--color-80-black);border:1px solid var(--color-70-black);color:var(--color-white);width:100%;}.i6eecos .ant-input:hover,.i6eecos .ant-input:focus{border-color:var(--color-link);}.i6eecos .ant-input::-webkit-input-placeholder{color:var(--color-60-gray);}.i6eecos .ant-input::-moz-placeholder{color:var(--color-60-gray);}.i6eecos .ant-input:-ms-input-placeholder{color:var(--color-60-gray);}.i6eecos .ant-input::placeholder{color:var(--color-60-gray);}
.ida2yl{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:0.5rem;background-color:var(--color-80-black);padding:12px 16px;border-radius:4px;width:100%;}
.iz1laae{color:var(--color-40-black);font-size:14px;}
.sijp6pg{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:0.5rem;}
.sq37xbm{color:var(--color-link);font-size:14px;text-overflow:ellipsis;overflow:hidden;}
.c1b5vg76{color:var(--color-60-gray);font-size:16px;cursor:pointer;-webkit-transition:color 0.3s ease;transition:color 0.3s ease;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;}.c1b5vg76:hover{color:var(--color-link);}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;