.audience-persona-section {
    margin-top: 20px;
    padding: 20px;
    background-color: var(--color-charcoal-surface-2);
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    color: var(--color-white);
}

.persona-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.persona-name {
    margin-bottom: 10px;
}

.persona-name-text {
    font-size: 22px;
    font-weight: bold;
    color: var(--color-white);
}

.persona-description {
    margin-bottom: 20px;
}

.persona-description-text {
    font-size: 18px;
    line-height: 1.6;
    color: #ccc;
}

.chat-button {
    align-self: flex-start;
}

.traits-table {
    margin-top: 20px;

    .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .toggle-view-button {
        color: var(--color-white) !important;
        cursor: pointer;
        background: none !important;
        border: none !important;
        box-shadow: none !important;

        &:hover,
        &:focus,
        &:active {
            background: none !important;
            box-shadow: none !important;
        }

        .anticon {
            color: var(--color-white) !important;
        }
    }
}

.controls-row {
    display: flex;
    justify-content: left;
    margin-bottom: 20px;

    .feature-select-section,
    .sort-select-section {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        label {
            margin-bottom: 8px;
            font-weight: bold;
        }
    }
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;