.createAudienceContainer {
  padding: 0 20px;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;

  .title {
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
  }

  .formItem {
    margin-bottom: 20px;
  }

  .helper-text {
    font-size: 0.9em;
    color: #888;
    margin-bottom: 8px;
  }

  .input {
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    color: #fff;
    border: 1px solid #555;
  }

  .card {
    border-radius: 8px;
    text-align: center;
    justify-content: center;
    height: 200px;
    /* Fixed height */
    width: 200px;
    /* Fixed width */
    cursor: pointer;
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .card-primary {
    background-color: var(--color-primary) !important;
  }

  .card-gray {
    background-color: var(--color-full-black) !important;
  }

  .card-hover {
    &:hover {
      transform: scale(1.05);
    }
  }

  .card-disabled {
    background-color: var(--color-full-black) !important;
    color: #aaa !important;
    cursor: not-allowed;

    .card-meta-title,
    .card-meta-description {
      color: #aaa !important;
    }
  }

  .card-meta-title {
    font-size: 18px;
    font-weight: bold;
    color: white !important;
  }

  .card-meta-description {
    font-size: 14px;
    color: white !important;
  }
  
  .ant-radio-inner {
    background-color: transparent;
  }

  .steps {
    margin-bottom: 20px;
    color: white !important;
  }

  .step-title {
    color: #fff !important;
  }

  .step-title-active {
    color: var(--color-primary) !important;
  }

  .step-icon {
    color: #fff !important;
  }

  .step-icon-active {
    color: var(--color-primary) !important;
  }

  .steps-item-inactive .steps-item-tail::after {
    background-color: #555 !important;
  }

  .ant-steps-item-title::after  {
    background-color: #f0f0f0 !important;
  }

  .advanced-settings {
    display: flex;
    align-items: center;
  }

  .advanced-settings-title {
    font-weight: bold;
    margin-right: 10px;
  }

  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    width: 100%;
  }

  .breadCrumb {
    margin-bottom: 16px !important;
  }
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;