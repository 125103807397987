.kpi-benchmarks-component {
  color: var(--color-white) !important;
  margin: 0px 8px;

  .ant-list-header {
    .kpi-header {
      display: flex;
      width: 100%;
      padding: 8px 8px;
      gap: 8px;
      background-color: var(--color-charcoal-surface-2);
      color: var(--color-table-header);
      font-weight: bold;
      border-bottom: 1px solid var(--color-border);

      .definition {
        width: 30%; // Set a fixed width for each column
      }

      .b2c-percent,
      .b2b-percent {
        width: 10%;
      }

      .custom-name-without-edit {
        width: 20%;
      }

      .b2c-percent-without-edit,
      .b2b-percent-without-edit {
        width: 16%;
      }

      .custom-name,
      .kpi-name,
      .benchmark-value {
        width: 15%;
      }

      .actions {
        width: 5% !important;
        display: flex !important;
        justify-content: flex-end !important;
      }
    }
  }

  .ant-list-item {
    background-color: var(--color-charcoal-surface-1);
    margin-bottom: 4px;

    &:hover {
      background-color: var(--color-hover-black);
    }

    .kpi-details {
      display: flex;
      width: 100%;
      gap: 8px;
      padding: 0px 8px;
      margin-left: 4px;

      .ant-select,
      .ant-input, .ant-input-number {
        width: 100% !important;
        height: 35px !important;
        background-color: var(--color-charcoal-surface-2) !important;
        color: var(--color-white) !important;
        border: none !important;
      }
      .ant-input-number {
        padding: 7px 16px !important;
      }

      .ant-input-number .ant-input-number-input:placeholder-shown {
        font-size: 12px !important; // Adjust the size as needed
        color: var(--color-input-placeholder) !important;
      }

      .ant-input::placeholder, .ant-input-number::placeholder {
        font-size: 12px !important; // Adjust the size as needed
        color: var(--color-input-placeholder) !important;
      }

      .ant-select-selector {
        border: none !important;
      }

      .ant-select-selection-placeholder {
        font-size: 12px !important;
      }
      .definition {
        width: 30%; // Set a fixed width for each column
      }

      .b2c-percent,
      .b2b-percent {
        width: 10%;
      }

      .custom-name,
      .kpi-name,
      .benchmark-value {
        width: 15%;
      }

      .custom-name-without-edit {
        width: 20%;
      }

      .b2c-percent-without-edit,
      .b2b-percent-without-edit {
        width: 16%;
      }

      .actions {
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-end !important;
        width: 5% !important;
        align-self: center !important;
        gap: 8px;
      }
    }
  }
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;