.ant-tabs-nav .ant-tabs-tab-btn {
  font-weight: 700 !important;
  color: var(--color-40-black) !important;
  text-transform: uppercase;
  font-size: 0.857rem !important;
}
.ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color-white) !important;
}
.ant-tabs-ink-bar {
  background-color: var(--color-primary) !important;
}

.ant-tabs-dropdown-menu {
  background: var(--color-charcoal-surface-2) !important;
}
.ant-tabs-dropdown-menu-item {
  &.ant-tabs-dropdown-menu-item-active {
    background: var(--color-full-black) !important;
  }
}
