@primary-color: var(--color-primary);
@background-color: var(--color-charcoal-black); // Fallback color if CSS variable is not supported
@input-background-color: var(--color-70-black);
@border-color: var(--color-60-black);
@text-color: var(--color-white);
@error-color: var(--feedback-dislike);
@secondary-color: var(--color-gale-blue);
@hover-background-color: var(--color-hover-black);
@disabled-background-color: var(--color-charcoal-black);
@disabled-text-color: var(--color-40-black);
@link-color: var(--color-link);
@link-hover-color: var(--color-primary-tint-90);
@success-color: var(--feedback-like);
@warning-color: var(--color-gale-yellow);
@info-color: var(--color-cyan);
@lighter-background-color: var(--color-80-black);

#data-source-tab-layout {
  padding: 20px;
  color: @text-color;
  border-radius: 8px;

  .buttonContainer {
    text-align: right;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
  .buttonRight{
    display: flex;
  }

  .errorBoundaryFallback {
    color: @error-color;
    text-align: center;
    padding: 20px;
    background-color: @lighter-background-color;
    border: 1px solid @border-color;
    border-radius: 8px;
  }

  .boolean-builder {
    background-color: @lighter-background-color;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid @border-color;

    h1 {
      color: @primary-color;
      text-align: center;
      margin-bottom: 20px;
    }

    .expression {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      width: 100%;

      .ant-select,
      .ant-input {
        background-color: @input-background-color;
        color: @text-color;
        border: 1px solid @border-color;
        margin-right: 10px;
      }

      .ant-select-selector {
        background-color: @input-background-color !important;
        color: @text-color !important;
      }

      .ant-select-arrow {
        color: @text-color;
      }

      .ant-input {
        background-color: @input-background-color;
        color: @text-color;
      }

      .ant-btn {
        background-color: @primary-color;
        border: 1px solid @border-color;
        color: @text-color;
        margin-right: 10px;

        &:hover {
          background-color: @hover-background-color;
        }
      }

      .delete-button {
        margin-right: 10px;
        color: @error-color !important;
        cursor: pointer;
        background: none;
        border: none;
      }
    }

    .group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 20px;
      border: 0.5px solid @border-color;
      border-radius: 8px;
      background-color: @input-background-color;
      width: 100%;

      .group-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .combinator-container {
        display: flex;
        align-items: center;
      }

      .ant-select,
      .ant-input {
        background-color: @input-background-color;
        color: @text-color;
        border: 1px solid @border-color;
        margin-right: 10px;

      }

      .ant-select-selector {
        background-color: @input-background-color !important;
        color: @text-color !important;
      }

      .ant-select-arrow {
        color: @text-color;
      }

      .ant-input {
        background-color: @input-background-color;
        color: @text-color;
      }

      .ant-btn {
        background-color: @primary-color;
        border: 1px solid @border-color;
        color: @text-color !important;
        margin-right: 10px;

        &:hover {
          background-color: @hover-background-color;
        }
      }

      .delete-button {
        color: @error-color !important;
        cursor: pointer;
        background: none;
        border: none;
      }
    }

    .combinator {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    .nested-rules {
      margin-left: 20px;
      border-left: 2px solid @border-color;
      padding-left: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
    }

    .add-buttons {
      display: flex;
      justify-content: flex-start;
      margin-top: 10px;

      .ant-btn {
        background-color: @primary-color;
        border: 1px solid @border-color;
        color: @text-color;

        &:hover {
          background-color: @hover-background-color;
        }
      }
    }
  }

  .audience-size {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .audience-size-label {
    font-weight: bold;
    font-size: 1.2em;
    margin-right: 4px;
  }

  .audience-size-value {
    font-size: 1.2em;
    margin-right: 4px;
  }

  .audience-size .anticon {
    margin-left: 10px;
  }

  .refresh-icon {
    cursor: pointer;
    transition: transform 0.3s ease;
    color: @primary-color;
    margin-right: 10px;
  }

  .refresh-icon:hover {
    transform: rotate(90deg);
  }

  #query-output {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid @border-color;
    background-color: @lighter-background-color;
    color: @text-color;
    border-radius: 8px;

    h2 {
      color: @primary-color;
      margin-bottom: 10px;
    }

    pre {
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow: hidden;
    }
  }
}

.error {
  color: @error-color;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;