.media-description {
    color: #8d8d8d;
    font-size: 14px;
    max-width: 75%;
    line-height: 1.5;

    @media (max-width: 768px) {
        font-size: 12px;
        max-width: 100%;
    }

    @media (max-width: 480px) {
        font-size: 10px;
    }
}

.media-btn-description {
    color: #8d8d8d;
    font-size: 14px;
    line-height: 1.5;
    margin-top: 8px;

    @media (max-width: 768px) {
        font-size: 12px;
    }

    @media (max-width: 480px) {
        font-size: 10px;
    }
}

.page-header-layout {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media (max-width: 480px) {
        margin-bottom: 16px;
        margin-top: 4px;
    }
}

.table-filter-main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 80px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 40px;
    }

    @media (max-width: 480px) {
        margin-top: 20px;
    }

    .search-media-plan {
        position: relative;
        bottom: 2px;

        @media (max-width: 768px) {
            bottom: 0;
        }
    }
}

.table-filter-section-one {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.mediaplanActionMenu {
    background-color: #333;
    color: #fff;
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;