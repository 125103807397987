/* Layout global styles */
.layout {
    z-index: 2;

    &.ant-layout {
        background-color: transparent;
        color: #fff;
    }
}

/* Responsive content area */
.page-container {
    &.ant-layout-content {
        margin: 0 auto;
        max-width: 1440px;
        padding: 0 20px;
        width: 100%;
        box-sizing: border-box;
    }
}

/* Responsive styles using LESS mixins */
@media (max-width: 1200px) {
    .page-container {
        max-width: 1024px;
    }
}

@media (max-width: 768px) {
    .page-container {
        max-width: 768px;
    }
}

@media (max-width: 576px) {
    .page-container {
        max-width: 100%;
        padding: 0 16px;
    }
}

.decorativeBackgroundImage {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 0;

    img {
        object-fit: cover;
        width: 100%;
        transition: all 0.2s;
    }

    @media screen and (min-width:786px) {
        img {
            width: 100%;
        }
    }
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;