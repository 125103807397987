
.ctc3491{box-shadow:rgba(149,157,165,0.2) 0px 8px 24px;}.ctc3491.ant-card-bordered{border-radius:10px;}.ctc3491.ant-card-bordered .ant-card-body{max-height:calc(100vh - 238px);overflow-y:auto;}
.l14g817m{color:gray;}
.d1856vyo{opacity:0.5;}
.c1bcsfxx{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}.c1bcsfxx .ant-form-item{margin-bottom:0.8rem;}.c1bcsfxx .ant-slider-rail{height:9px;}.c1bcsfxx .ant-slider-track{height:9px;}.c1bcsfxx .ant-slider-handle{width:18px;height:18px;}.c1bcsfxx .ant-slider-dot{width:14px;height:14px;}.c1bcsfxx .ant-input-number{padding:8px 16px !important;}
.p4915if{width:98%;}.p4915if.ant-progress-success-bg,.p4915if .ant-progress-bg{height:25px !important;}.p4915if .ant-progress-status-success .ant-progress-text{color:white;}.p4915if .ant-progress-success-bg,.p4915if .ant-progress-bg{border-radius:0;}.p4915if .ant-progress-inner{border-radius:0;background-color:var(--color-full-black);}
.f150ykmw{overflow-y:auto;margin-top:1rem;overflow-x:hidden;margin-left:1rem;}
.p1w1mxs0{padding:4px 14px;background-color:#91dd91;}
.dsrkqtc{opacity:0.7;pointer-events:none;cursor:not-allowed;}
.attuz8h{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:16px;margin-top:1rem;margin-bottom:1rem;padding:16px 24px 24px 24px;border-radius:8px;border:0.5px solid var(--color-border);}
.f12658gx{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:8px;padding:12px;border-radius:8px;background:var(--color-70-black);box-shadow:0 2px 5px var(--color-box-shadow);}
.mf0d744{background:transparent;margin-top:1rem;margin-bottom:1rem;text-align:center;border-radius:8px;border:0.5px solid var(--color-border);}
.g7qrp2u{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:space-around;-webkit-justify-content:space-around;-ms-flex-pack:space-around;justify-content:space-around;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:32px;}
.e11p4qlm{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flexdirection:column;-ms-flexdirection:column;flexdirection:column;justifycontent:stretch;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;