.cwqhetj{background:black;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;overflow-y :auto;}
.aera9f6{padding:0 16px;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;max-width:1440px;margin:0 auto;}@media screen and (min-width:768px){.aera9f6{padding:0px 60px;}}
.b10ubbap{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;z-index:1000;padding:0.8rem;}
.agte0m4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.ud9a5jx{font-size:1.1rem;margin-bottom:1rem;font-weight:600;}
.p1yqp4h4 .ant-popover-inner{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;border-radius:5px;}
.ty5phlk{padding-bottom:24px;}
.a29lurz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:end;-webkit-justify-content:end;-ms-flex-pack:end;justify-content:end;}
.fv48kis{color:white;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;margin-bottom:1rem;font-size:1.1rem;font-weight:500;}
.c12yr76p{background:#db5a4b !important;color:white !important;font-weight:600 !important;border:0 !important;height:2.2rem !important;padding-left:0.5rem !important;padding-right:0.5rem !important;}
.egg6byt.ant-btn-default{border-radius:8px;border:0px !important;background:#db5a4b;text-align:center;text-transform:capitalize;font-weight:600;height:2.2rem;padding-left:0.5rem;padding-right:0.5rem;color:white !important;}.egg6byt.ant-btn-default:hover{border:0px;background:#db5a4b;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;