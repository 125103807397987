.ryjwpco{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;-webkit-flex-flow:nowrap;-ms-flex-flow:nowrap;flex-flow:nowrap;margin-top:16px;}
.cwdjp5i.ant-col{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;height:80vh;max-height:80vh;overflow-y:auto;border-right:solid 1px white;}
.l59jzki{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;color:white;fontsize:1.3rem;}
.s1945295{width:200px;}
.rzj8fe0 .ant-radio-group{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}.rzj8fe0 .ant-radio-wrapper{color:white;}.rzj8fe0 .ant-radio-checked .ant-radio-inner{border-color:#555555;}.rzj8fe0 .ant-radio-inner{border-color:white;}.rzj8fe0 .ant-radio-inner::after{background-color:#555555;}.rzj8fe0 .ant-radio-input:focus + .ant-radio-inner{border-color:#555555;}
.igezyua{max-width:240px;}
.dmc2gze{max-width:240px;}
.h1f1u6dp.ant-divider-horizontal{border-top:solid 0.5px white;opacity:50%;width:92%;min-width:92%;margin:8px 0 24px;}
.pp9h3sq{display:block;margin:0 auto;position:relative;top:20px;}.pp9h3sq .ant-pagination{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.pp9h3sq .ant-pagination .ant-pagination-prev .ant-pagination-item-link{background-color:transparent;border:0 none;}.pp9h3sq .ant-pagination .ant-pagination-prev .ant-pagination-item-link .anticon{color:#d9d9d9;opacity:70%;}.pp9h3sq .ant-pagination .ant-pagination-item{background-color:transparent;border:0 none;}.pp9h3sq .ant-pagination .ant-pagination-item a{color:#d9d9d9;opacity:50%;}.pp9h3sq .ant-pagination .ant-pagination-item-active{background-color:transparent;border:0 none;}.pp9h3sq .ant-pagination .ant-pagination-item-active a{color:#d9d9d9;font-weight:bold;opacity:100%;}.pp9h3sq .ant-pagination .ant-pagination-next .ant-pagination-item-link{background-color:transparent;border:0 none;}.pp9h3sq .ant-pagination .ant-pagination-next .ant-pagination-item-link .anticon{color:#d9d9d9;opacity:70%;}
.vxsdz56{margin-bottom:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;display:none;}
.c15thw0r{width:100%;min-width:300px;}.c15thw0r.ant-card{width:100%;margin-bottom:8px;border-radius:10px;color:var(--color-white);background:var(--color-charcoal-surface-3);cursor:pointer;box-shadow:none;}.c15thw0r.ant-card .ant-card-body{padding:0 0 16px 0;}@media screen and (min-width:992px){.c15thw0r{min-width:0;}.c15thw0r.ant-card{margin-bottom:0px;margin-right:8px;}}
.cewi7vh{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:var(--color-charcoal-surface-1);color:var(--color-white);border-top-left-radius:10px;border-top-right-radius:10px;padding:6px 16px;min-height:57px;}
.c1bipayl{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:baseline;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;color:#191919;}.c1bipayl .anticon{font-size:1rem;}
.c1ld3jx.ant-btn.ant-btn-default{height:32px;border-radius:8px;position:relative;top:2px;}
.c1ckmwlw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:16px;padding-bottom:0;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:baseline;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;}
.c4u9red{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;width:100%;}
.c175x395{font-weight:bold;margin-bottom:0px;font-size:14px;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;overflow:hidden;text-overflow:ellipsis;position:relative;right:2px;}
.cavvsra{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:100%;}
.crgpbtq{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:space-evenly;-webkit-justify-content:space-evenly;-ms-flex-pack:space-evenly;justify-content:space-evenly;margin-top:14px;margin-botto:4px;width:100%;}
.cjekgj{margin-bottom:4px;font-size:12px;}
.cjxy2s1{font-size:12px;margin-bottom:0px;font-weight:500;text-transform:capitalize;}
.cdgq3bp{font-size:10px;margin-bottom:0px;color:var(--color-white);}
.u1fo19fz{margin-left:8px;max-width:200px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:var(--color-white);}
.f1xqp7bz{height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;border-radius:10px;width:100%;}@media screen and (min-width:992px){.f1xqp7bz{height:calc(100vh - 378px);}}
.snjy4ja{overflow-y:auto;height:100%;-webkit-flex:0 0 auto;-ms-flex:0 0 auto;flex:0 0 auto;-webkit-flex-basis:30%;-ms-flex-preferred-size:30%;flex-basis:30%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;color:black;margin-bottom:16px;}@media screen and (min-width:992px){.snjy4ja{margin-bottom:0;}}
.s1htcpg3{overflow-y:auto;-webkit-flex:0 0 auto;-ms-flex:0 0 auto;flex:0 0 auto;height:100%;-webkit-flex-basis:70%;-ms-flex-preferred-size:70%;flex-basis:70%;border-radius:10px;background-color:var(--color-charcoal-surface-2);padding:16px 40px;}
.v1wedpvv{width:1px;background-color:#ccc;height:100%;}
.c1v283s8{height:100%;}.c1v283s8 .ant-space-item{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.c4gq1by{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;}
.fmjigrp .collapse_c7hb0xd.ant-collapse .ant-collapse-item.ant-collapse-item.ant-collapse-item .ant-collapse-content{max-width:900px;width:100%;}.fmjigrp .c7hb0xd.ant-collapse .ant-collapse-item.ant-collapse-item.ant-collapse-item .ant-collapse-content{max-width:900px;width:100%;}
.c15h6k0w{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin-left:0.5rem;font-weight:400;width:calc(100% - 3.4rem);margin-bottom:0.5rem;}
.m1fgsxa3{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;-webkit-align-items:baseline;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;font-weight:600;color:black;margin-bottom:0.2rem;font-size:16px;color:var(--color-white);}
.m1he7s94{font-weight:600;margin-bottom:0.2rem;font-size:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;gap:8px;margin-bottom:8px;}
.t148snpj{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.r18rfqlb{margin-top:0.2rem;font-size:1rem;}.r18rfqlb > *{opacity:0.7;-webkit-transition:-webkit-transform 0.2s;-webkit-transition:transform 0.2s;transition:transform 0.2s;}.r18rfqlb > *:hover{opacity:1;-webkit-transform:scale(1.1);-ms-transform:scale(1.1);transform:scale(1.1);}
.t16b42vq{border-radius:0.5rem;padding-bottom:0.5rem !important;}.t16b42vq .ant-tooltip-content{border-radius:0.5rem;font-size:10px;}.t16b42vq .ant-tooltip-content .ant-tooltip-inner{border-radius:0.5rem;margin:5px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.t188m8dn{color:#1c1c1c;font-size:0.7rem;margin-left:8px;color:var(--color-gale-gray);margin-right:30px;}
.t2q6z8a{float:right;visibility:unset;color:#1c1c1c;font-size:0.7rem;}
.f1rrsiut{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;background:var(--color-80-black);gap:8px;-webkit-align-content:flex-start;-ms-flex-line-pack:start;align-content:flex-start;min-height:30px;-webkit-transition:min-height 1s ease-in 1s;transition:min-height 1s ease-in 1s;}.f1rrsiut.expand{gap:12px;padding:16px;width:90%;min-height:100vh;-webkit-transition:min-height 0.1s ease-in;transition:min-height 0.1s ease-in;}.f1rrsiut.expand > *{width:100%;max-width:none;}@media screen and (min-width:992px){.f1rrsiut{width:100%;background:transparent;-webkit-transform:transitionX(0);-ms-transform:transitionX(0);transform:transitionX(0);}}
.fail766{position:fixed;top:0;left:0;z-index:1001;-webkit-transform:translateX(-100%);-ms-transform:translateX(-100%);transform:translateX(-100%);width:100%;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;-webkit-transition:-webkit-transform 0.1s;-webkit-transition:transform 0.1s;transition:transform 0.1s;}.fail766.expand{-webkit-transform:translateX(0);-ms-transform:translateX(0);transform:translateX(0);-webkit-transition:-webkit-transform 0.2s;-webkit-transition:transform 0.2s;transition:transform 0.2s;}@media screen and (min-width:992px){.fail766{position:relative;-webkit-transform:translateX(0);-ms-transform:translateX(0);transform:translateX(0);min-height:none;}}
.fybgjgo{padding:10px 0 24px;background:rgba(0,0,0,0.4);min-height:30px;width:100%;opacity:0;-webkit-transition:min-height 1s ease-in 1s;transition:min-height 1s ease-in 1s;}.fybgjgo.expand{opacity:1;background:rgba(0,0,0,0.4);padding:0;width:100vw;min-height:100vh;-webkit-transition:min-height 0.1s ease-in;transition:min-height 0.1s ease-in;}@media screen and (min-width:992px){.fybgjgo{opacity:1;width:100%;position:relative;background:transparent;}}
.f11q5hzm{border:none;background:transparent;color:var(--color-white);cursor:pointer;-webkit-transition:all 0.2s;transition:all 0.2s;}.f11q5hzm:hover{color:var(--color-primary);}
.n4w9lik{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;height:100%;}
.h1k7ndur{margin-top:7vh;}@media screen and (max-width:768px){.h1k7ndur{margin-top:1rem;}}
.hlu8a1m{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;margin-top:1rem;font-size:18px;font-weight:600;}@media screen and (max-width:768px){.hlu8a1m{font-size:14px;}}
.d1i5j138.chatDesc{color:lightCoral;}
.l15r5t19 .chatDesc{color:lightGreen;}
.p1rg38ft{-webkit-scroll-behavior:smooth;-moz-scroll-behavior:smooth;-ms-scroll-behavior:smooth;scroll-behavior:smooth;}
.ma50136{background-color:rgba(0,0,0,0.4);}

.q159irq4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;}
.q14elrm3{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;float:right;max-width:80%;margin-bottom:8px;border-radius:16px;background:var(--color-charcoal-surface-3);padding:12px 16px;color:var(--color-gale-gray);margin-right:30px;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;