.container {
  .ant-checkbox-disabled + span {
    color: var(--color-white);
  }
}

.list {
  .ant-list-header {
    border-bottom: none !important;
    padding-bottom:0 !important;
  }
  margin-bottom: 1rem;
}

.listHeader{
  display: flex;
  flex-direction: row;
  color: white;
  border: none;
  padding: 24px;
}

.listItem {
  margin-top: 1rem;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0px;
  font-size: 14px;
  background: var(--color-charcoal-surface-1);
  color: var(--color-white);
  gap:8px;
  @media screen and (min-width:768px) {
    padding: 24px;
    flex-direction: row;
  }
}

.listItemInner {
  .list-item-label {
    color:var(--color-40-black);
    margin-bottom:0;
  }
  .list-item-value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom:0;
  }
}

.deleteContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;