.ant-table-pagination.ant-pagination,
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--color-white);
  font-size: 1rem;
  font-variant: tabular-nums;
  line-height: 100%;
  list-style: none;
  font-feature-settings: 'tnum';

  li {
    background: transparent;
  }

  .ant-pagination-item {
    background-color: transparent;
    border:none;
    a {
      background: transparent;
      color: var(--color-white);
    }
    &:hover,
    &:focus {
      a{
        color: var(--color-primary) !important;
      }
    }
  }

  .ant-pagination-item-link{
    background: transparent !important;
    border:none;
    color:var(--color-white);
    &:hover {
      color:var(--color-primary) !important;
      a {
        color:var(--color-primary) !important;
      }

    }
    &:disabled {
      color:var(--color-60-black) !important;
    }
    
  }
  .ant-pagination-item-active {
    font-weight: bold;
    border:none !important;
    // color:var(--color-white);
    color:var(--color-primary) !important;
    a{
      color:var(--color-primary) !important;
    }
    .ant-btn-text {
      color:var(--color-primary) !important;
    }
  }

  .ant-btn-text {
    border:none !important;
    color: var(--color-white);
    &:hover {
      color:var(--color-primary);
    }

    &:disabled {
      color:var(--color-60-black);
    }
  }
  
  .ant-pagination-prev, .ant-pagination-next, 
  .ant-pagination-jump-prev, .ant-pagination-jump-next {
    color: var(--color-white) !important;
  }
  
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, 
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color:var(--color-primary);
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, 
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: var(--color-white) !important;
  }
}