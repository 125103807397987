.feature-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
}

.category {
    position: relative;
    background-color: var(--color-charcoal-surface-2);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    min-height: 300px;
    /* Ensure a minimum height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.category-title {
    font-size: 18px;
    color: var(--color-primary);
}

.features {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
    /* Allow the features section to grow */
}

.feature {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid var(--color-60-black);
}

.feature-header {
    font-weight: bold;
    border-bottom: 2px solid var(--color-primary);
}

.feature-name {
    flex: 1;
    font-size: 14px;
    color: var(--color-white);
}

.feature-pen {
    width: 60px;
    text-align: center;
    font-size: 14px;
    color: var(--color-white);
}

.feature-index {
    width: 40px;
    text-align: center;
    font-size: 14px;
    color: var(--color-white);
}

.button-delete {
    color: var(--color-white);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: color 0.3s ease;

    &:hover {
        color: var(--color-primary);
    }
}

.category-list {
    max-height: 300px;
    background-color: var(--color-charcoal-surface-1);
    overflow-y: auto;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin: 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: var(--color-charcoal-surface-3);
        transform: translateY(-2px);
    }
}

.loading-spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* Ensure the spinner takes up the full height of the category */
    background-color: var(--color-charcoal-surface-1);
    /* Match the background color of the feature */
    color: var(--color-white);
    /* Ensure the text color is white */
    text-align: center;
    /* Center align the text */
}
@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;