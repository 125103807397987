.u9a6g24{font-size:1.1rem;margin-bottom:1rem;font-weight:600;cursor:pointer;}
.t1bgb1y7{padding:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;background-color:transparent;z-index:1000;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;position:relative;pointer-events:none;left:0;top:0;margin:0 auto;}@media screen and (min-width:768px){.t1bgb1y7{padding:32px 52px;}}@media screen and (max-width:768px){.t1bgb1y7{-webkit-transform:none !important;-ms-transform:none !important;transform:none !important;}}.t1bgb1y7.chatbot-bar{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:16px;}.t1bgb1y7.sticky{position:-webkit-sticky;position:sticky;}.t1bgb1y7 div{pointer-events:auto;}
.ab6nmn0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;cursor:pointer;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:10px;margin-right:12px;background:transparent;border:none;}.ab6nmn0 span{font-size:1rem;font-weight:500;}
.cww9kr9{background:transparent;border:none;}
.c1w3ukz4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;max-height:30px;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:12px;}.c1w3ukz4 img{max-width:60px;max-height:40px;objectfit:contain;cursor:pointer;}
.e1tth36j.ant-btn-primary{border-radius:8px;border:0px;background:#db5a4b;text-align:center;text-transform:capitalize;font-weight:600;height:2.2rem;padding-left:0.5rem;padding-right:0.5rem;}.e1tth36j.ant-btn-primary:hover{border:0px;background:#db5a4b;}
.p1n2s3w .ant-popover-inner{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;border-radius:5px;}
.azi7qsr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.lzybhnl{max-width:180px;-webkit-transition:all 0.3s;transition:all 0.3s;}.lzybhnl.chat-mode{max-width:100px;}
.u1xjmrft{cursor:pointer;border:none;background:transparent;padding:0;-webkit-transition:all 0.3s;transition:all 0.3s;}.u1xjmrft:hover{opacity:0.7;}
.udbm744{margin-right:8px;}
.m1pxp4ti{cursor:pointer;padding:0;pointer-events:auto;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;border:none;background:transparent;}.m1pxp4ti img{max-height:30px;}

@primary-color: #DC5A4B;@info-color: #DC5A4B;@link-color: #DC5A4B;@success-color: #52c41a;@warning-color: #faad14;@error-color: #E06B5D;@font-size-base: 14px;@heading-color: #191919;@text-color: #ffffff;@text-color-secondary: #696969;@disabled-color: rgba(0, 0, 0, 0.25);@border-color-base: #DC5A4B;@label-color: #ffffff;@picker-bg: gray;@picker-basic-cell-hover-color: #DC5A4B;@picker-basic-cell-active-with-range-color: #5e423f;@picker-basic-cell-hover-with-range-color: #5e423f;@picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);@picker-border-color: #FFFFFF;@picker-date-hover-range-border-color: #DC5A4B;@picker-date-hover-range-color: #DC5A4B;