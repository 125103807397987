.utility-btn {
  border:none;
  background:transparent;
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
  text-transform: capitalize;
  transition: all 0.2s;
  &:hover {
    color:var(--color-primary);
  }
}

.ant-btn-primary{
  height:fit-content;
  width: 100%;
  font-size: 1.143rem;
  line-height: 120%;
  font-weight: 600 !important;
  background: var(--color-full-black);
  border-radius: 0;
  border: 1px solid var(--color-white);
  color:var(--color-white);
  padding: 16px 48px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: uppercase;
  transition: all 0.3s;

  &.small {
    font-weight: 500 !important;
    padding: 6px 24px !important;
    font-size:1rem;
    text-transform: none;
  }

  &:hover {
    background: var(--color-full-black) !important;
    border-color:var(--color-primary) !important;
  }

  &:focus {
    background: var(--color-full-black);
    border-color:var(--color-primary-tint-90) !important;
  }

  &:disabled {
    background: var(--color-full-black);
    border: 1px solid var(--color-white);
    color:var(--color-white);
    pointer-events: none;
    opacity: 0.3;
  }

  @media screen and (min-width: 768px) {
    width: fit-content;
  }
}

.ant-btn-default {
  height:fit-content;
  width: 100%;
  font-size: 1.143rem;
  line-height: 120%;
  font-weight: 600 !important;
  background: var(--color-white);
  color:var(--color-full-black) !important;
  border:1px solid var(--color-white);
  border-radius: 0;
  padding: 16px 48px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: uppercase;
  transition: all 0.3s;

  &.small {
    font-weight: 500 !important;
    padding: 6px 24px !important;
    font-size:1rem;
    text-transform: none;
  }

  &:hover {
    background: var(--color-primary);
    border:1px solid var(--color-primary);
  }

  &:focus {
    background: var(--color-primary-tint-90);
    border:1px solid var(--color-primary-tint-90);
  }

  &:disabled {
    pointer-events: none;
    background: var(--color-white);
    opacity: 0.3;
  }

  @media screen and (min-width: 768px) {
    width: fit-content;
  }
}

.delete-btn {
  color:var(--color-primary);
  &:hover {
    color:var(--color-primary-tint-90);
  }
}